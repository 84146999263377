import withStyles from '@material-ui/styles/withStyles';
import { withAuth } from '@okta/okta-react';
import { DrcPanel } from 'driscolls-react-components';
import { default as React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DrcTab from '../../components/DrcTab/DrcTab';
import Location from './Location';
import Quartile from './Quartile';
import { setPageDirty, setPageTitleAction } from '../../actions/actions';
import { subTabByPassRouting } from '../../util/navigation';
import DirtyCheck from '../../components/DirtyCheck';

let styles = () => ({
    layout: {
        width: '97%'
    }
});

let changingTabIndex = 0;
let currentIndex = 0;
const Bypass = (props) => {
    const [screenHasDataChanges, setScreenHasDataChanges] = useState(false);

    useEffect(() => {
        let pageTitle = subTabByPassRouting(props.match.path);
        props.setPageTitle(pageTitle);
    }, [props.match.path]);

    const continueNavigation = () => {
        currentIndex = changingTabIndex;
        if (changingTabIndex === 0) {
            props.history.push('/Bypass/Location/');
            props.setPageTitle('Bypass Location');
        } else if (changingTabIndex === 1) {
            props.history.push('/Bypass/Quartile/');
            props.setPageTitle('Bypass Quartile');
        }
    };

    const handleSubTabChange = (index) => {
        changingTabIndex = index;
        if (props.isPageDirty) {
            setScreenHasDataChanges(true);
            return;
        }
        currentIndex = index;
        continueNavigation();
    };

    const handleConfirmReject = () => {
        setScreenHasDataChanges(false);
        props.setPageDirty(false);
        continueNavigation();
    };

    const handleCancelNavigation = () => {
        setScreenHasDataChanges(false);
    };
    const { subTabItems, selectedSubTabIndex } = props;
    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', padding: '0 15px 5px', margin: '0 auto 5px' }}
            className={props.classes.layout}
        >
            <DrcTab
                style={{ maxWidth: '100%' }}
                selectedMenuIndex={selectedSubTabIndex}
                menuItems={subTabItems}
                handleMenuItemChange={handleSubTabChange}
                centered
            ></DrcTab>

            {selectedSubTabIndex === 0 && <Location />}
            {selectedSubTabIndex === 1 && <Quartile />}
            {screenHasDataChanges && (
                <DirtyCheck
                    screenHasDataChanges={screenHasDataChanges}
                    handleConfirmReject={handleConfirmReject}
                    handleCancelNavigation={handleCancelNavigation}
                ></DirtyCheck>
            )}
        </DrcPanel>
    );
};

const mapStateToProps = (state) => {
    return {
        isPageDirty: state.rootReducer.isPageDirty
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageDirty: (isDirty) => dispatch(setPageDirty(isDirty)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withAuth(withStyles(styles)(Bypass))));
