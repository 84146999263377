import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Select from 'react-select';

let styles = (theme) => ({
    root: {
        height: 'auto',
        margin: '-12px 0 12px', //This is confirmed to work in the filters, if this needs changing we need to take a look at this and work on it a bit more
        paddingTop: '2px',
        '& .select > div': {
            // minHeight: 35
        }
    },
    label: {
        backgroundColor: theme.light.primary,
        color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : ''),
        fontSize: '.75rem',
        padding: '2px 4px',
        marginLeft: '10px',
        position: 'relative',
        top: '5px',
        zIndex: '2',
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary,
            color: theme.dark.text.primary
        },
        '& span': {
            fontSize: '.75rem',
            color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : '')
        }
    },
    labelError: {
        color: theme.light.text.errorTitle + theme.important,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle + theme.important
        }
    },
    pError: {
        margin: '-5px 12px 0',
        fontSize: '0.75rem',
        lineHeight: '1em',
        letterSpacing: '0.03333em',
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute'
    }),
    control: (provided) => ({
        ...provided,
        outline: 'none',
        // height: '35px',
        width: '100%',
        backgroundColor: 'transparent'
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: '1400'
    })
};

let colorSchemeWatch = window.matchMedia('(prefers-color-scheme: dark)');

class DrcMultiSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            styleKey: '',
            styles: {}
        };

        this.matchMediaWatch = this.matchMediaWatch.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    matchMediaWatch(e) {
        let isDarkTheme = e.matches;
        let isError = (this.props.helperText || '').length > 0;
        let styleKey = isDarkTheme.toString() + isError.toString();

        if (this.state.styleKey !== styleKey) {
            this.onLoad();
        }
    }

    onLoad() {
        let isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
        let isError = (this.props.helperText || '').length > 0;
        let styleKey = isDarkTheme.toString() + isError.toString();

        if (this.state.styleKey !== styleKey) {
            const { theme } = this.props;
            const checkThemeError = isDarkTheme ? theme.dark.error : theme.light.error;
            const buttonBorder = isDarkTheme ? theme.dark.buttonBorder : theme.light.buttonBorder;
            const borderHover = isDarkTheme ? theme.dark.text.primary : theme.light.text.primary;

            let styles = {
                primary25: isDarkTheme ? theme.dark.accent.brightness(-10) : theme.light.accent.brightness(10),
                primary: isDarkTheme ? theme.dark.accent.primary : theme.light.accent.primary,
                neutral0: isDarkTheme ? theme.dark.primary : theme.light.primary, //option background
                neutral5: isDarkTheme ? theme.dark.variant.primary : theme.light.variant.primary, //Unknown
                neutral10: isDarkTheme ? theme.dark.secondary : theme.light.secondary, //multi-item-box
                neutral20: isError ? checkThemeError : buttonBorder, //Border
                neutral30: isError ? checkThemeError : borderHover, //Border Hover
                neutral80: isDarkTheme ? theme.dark.text.primary : theme.light.text.primary, //Text
                dangerLight: isDarkTheme ? theme.dark.buttonBorder : theme.light.buttonBorder, //multi-clear-btn
                danger: isDarkTheme ? theme.dark.text.primary : theme.light.text.primary //multi-clear-x
            };

            this.setState({ styleKey, styles });
        }
    }

    componentDidMount() {
        colorSchemeWatch.addListener(this.matchMediaWatch);
        this.onLoad();
    }

    componentDidUpdate() {
        this.onLoad();
    }

    componentWillUnmount() {
        colorSchemeWatch.removeListener(this.matchMediaWatch);
    }

    onChange(option, actionType) {
        if (this.props.filterChanged) {
            this.props.filterChanged({ value: option.value, label: option.label, type: this.props.name });
        }

        if (this.props.onChange) {
            this.props.onChange(option, actionType);
        }
    }

    render() {
        const {
            isMulti,
            className,
            children,
            label,
            onChange,
            value,
            selectStyle,
            helperText,
            hideLabel,
            classes,
            theme,
            isCreatable,
            required,
            options,
            ...other
        } = this.props;

        let isError = (helperText || '').length > 0;
        let title = label || this.props.name;
        let name = this.props.name || label;
        let errorClass = isError ? classes.labelError : '';

        return (
            <div className={`${classes.root} ${className || ''}`}>
                {!hideLabel ? (
                    <label className={`${classes.label} ${errorClass}`} id={title + 'Lbl'}>
                        {required ? (
                            <span>
                                {title} <span className={classes.labelError}>*</span>
                            </span>
                        ) : (
                            title
                        )}
                    </label>
                ) : null}
                {isError ? <p className={classes.pError}>{helperText}</p> : null}
                <Select
                    name={name}
                    aria-labelledby={title + 'Lbl'}
                    value={value}
                    onChange={this.onChange}
                    options={options}
                    className="select"
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    theme={(themes) => ({
                        ...themes,
                        colors: {
                            ...themes.colors,
                            ...this.state.styles
                        }
                    })}
                    isMulti={isMulti}
                    {...other}
                />
            </div>
        );
    }
}

export default withTheme(withStyles(styles)(DrcMultiSelect));
