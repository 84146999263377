import React from 'react';
import TabRouting from '../../components/TabRouting';

const selectedMainTabIndex = 7;
const selectedSubTabIndex = 1;
const GrowerPreferencesMain = () => {
    return <TabRouting selectedMainTabIndex={selectedMainTabIndex} selectedSubTabIndex={selectedSubTabIndex}></TabRouting>;
};

export default GrowerPreferencesMain;
