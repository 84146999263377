import { DISPOSITION_DETAILS, FACTOR_TYPE } from '../Constant/ActionConstant';

const initialState = {
    factorType: [],
    dispositionDetails: [],
    dispositionOption: []
};

const dispositionReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISPOSITION_DETAILS:
            return { ...state, dispositionDetails: action.payload };
        case FACTOR_TYPE:
            let factorType = action.payload.map((item) => {
                return { value: item.LookupCode, label: item.LookupCode };
            });
            return { ...state, factorType };
        default:
            return state;
    }
};

export default dispositionReducer;
