import React from 'react';
import { DrcCheckbox } from 'driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({});

class CheckboxFormatter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }
    onCheckChange = (event, selected) => {
        this.props.onSelectionChange(event, selected);
        this.setState({ selected: selected });
    };

    componentDidMount = () => {
        this.setState({ selected: this.props.selected });
    };

    render() {
        return (
            <DrcCheckbox
                checked={this.state.selected}
                style={{ color: this.props.disabled ? 'rgba(0, 0, 0, 0.26)' : '#563e70' }}
                onChange={(event, selected) => {
                    this.onCheckChange(event, selected);
                }}
                {...this.props}
            />
        );
    }
}

export default withStyles(styles)(CheckboxFormatter);
