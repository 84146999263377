export const berryTypes = [
    {
        value: 'BLACK',
        label: 'BLACK'
    },
    {
        value: 'BLUE',
        label: 'BLUE'
    },
    {
        value: 'RASP',
        label: 'RASP'
    },
    {
        value: 'STRAW',
        label: 'STRAW'
    }
];
