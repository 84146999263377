import { THRESHOLD_LIST_DATA, USER_PRODUCING_AREA_THRESHOLD, M_LOOKUP_LIST_DATA } from '../Constant/ActionConstant';

const initialState = {
    thresholdList: [],
    userProducingArea: [],
    mLookupList: []
};
const RejectionThresholdReducer = (state = initialState, action) => {
    switch (action.type) {
        case THRESHOLD_LIST_DATA:
            return { ...state, thresholdList: action.payload };
        case USER_PRODUCING_AREA_THRESHOLD:
            let userProducingArea = action.payload.map((item) => {
                return { value: item.ProducingArea, label: item.ProducingAreaCode };
            });
            return { ...state, userProducingArea: userProducingArea };

        case M_LOOKUP_LIST_DATA:
            let mlookup = action.payload.map((item) => {
                return { value: item.LookupCode, label: item.LookupCode };
            });
            return { ...state, mLookupList: mlookup };

        default:
            return state;
    }
};

export default RejectionThresholdReducer;
