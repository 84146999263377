import { GET_PRODUCING_AREA_REGION, USER_PRODUCING_AREA, M_LOOKUP_LIST_DATA } from '../Constant/ActionConstant';

export const setProducingAreaRegionData = (data) => ({
    type: GET_PRODUCING_AREA_REGION,
    payload: data
});

export const setUserProducingArea = (data) => ({
    type: USER_PRODUCING_AREA,
    payload: data
});
export const setMLookUpData = (data) => ({
    type: M_LOOKUP_LIST_DATA,
    payload: data
});
