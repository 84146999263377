import { Data } from 'react-data-grid-addons';

const handleFilterChange = (data, filters) => {
    const selectors = Data.Selectors;
    const getRows = (rows, filter) => {
        return selectors.getRows({ rows, filter });
    };

    return getRows(data, filters);
};

export { handleFilterChange };
