import { GET_GROWER_LIST, GET_FACTOR_TYPE_LIST, GET_GROWER_PREFERENCES_LIST, GET_RANCH_LIST} from '../Constant/ActionConstant';

export const setGrowerData = (data) => ({
    type: GET_GROWER_LIST,
    payload: data
});

export const setFactorTypeData = (data) => ({
    type: GET_FACTOR_TYPE_LIST,
    payload: data
});
export const setGrowerPreferencesData = (data) => ({
    type: GET_GROWER_PREFERENCES_LIST,
    payload: data
});
export const setRanchData = (data) => ({
    type: GET_RANCH_LIST,
    payload: data
});
