import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import withStyles from '@material-ui/styles/withStyles';
import { withAuth } from '@okta/okta-react';
import { hideLoadingScreenAction, showLoadingScreenAction } from 'actions/actions';
import { DrcBackdrop, DrcButton, DrcDialog, DrcGrid, DrcIcons, DrcPanel, DrcSelect, DrcTooltip } from 'driscolls-react-components';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QuartileBatchProcess from 'services/QuartileBatchProcess';
import { setPageDirty } from '../../actions/actions';
import { setBerryType } from '../../actions/PointMatrixAction';
import { filterLocation, getQuartileByPass, getVariety, setEstimatedGroup, updateQuartileBypass } from '../../actions/QuartileActions';
import BerryTypeSelect from '../../components/BerryTypeSelect';
import QuartileByPassTable from '../../components/Bypass/QuartileBypassTable';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import {
    BERRY_TYPE,
    ConfirmAcceptBtn,
    ConfirmRejectBtn,
    CREATED_BY,
    ESTIMATE_GROUP,
    ESTIMATE_GROUP_PLACEHOLDER,
    LOCATION,
    LOCATION_PLACEHOLDER,
    MODIFY,
    OVERRIDE_ADD,
    QUARTILE,
    QUARTILE_ADD,
    QUARTILE_MODIFY,
    VARIETY,
    VARIETY_PLACEHOLDER
} from '../../language/english';
import APIEndPoints from '../../services/api';
import { getQuartilePayload, quartileDialogGridData } from '../../util/utils';

const PAGE_TYPE = 'quartileByPass';
const styles = (theme) => ({
    gridTitle: {
        marginTop: '0px',
        marginBottom: '8px',
        fontSize: '21px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#4a773c'
    },
    gridCount: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#6f5091;'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper
    },
    radioButton: {
        width: '410px'
    },
    gridTableColumn: {
        height: '230px',
        '& .react-grid-Grid': {
            minHeight: '230px!important'
        },
        '& .react-grid-Canvas': {
            height: '193px!important'
        },
        '& .rdg-cell-action': {
            float: 'left'
        },
        '& .react-grid-Row': {
            margin: '10px 0px'
        },
        '& .react-grid-Row.react-grid-Row:hover': {
            backgroundColor: 'transparent'
        },
        '& .react-grid-Row.react-grid-Row--odd': {
            backgroundColor: 'transparent'
        },
        '& .MuiToggleButton-root.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            color: 'gray'
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '800px',
            width: '100%'
        }
    }
});

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        width: '120px',
        height: '35px',
        backgroundColor: '#d8c9e6',
        color: '#000',
        borderRadius: '8px',
        '&.MuiToggleButton-root': {
            border: '0px'
        },
        '&.MuiToggleButton-root:hover': {
            backgroundColor: '#4b3761',
            color: '#FFF',
            border: '0px'
        },
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#6F5091',
            opacity: 1,
            color: '#FFF',
            border: '0px'
        }
    }
}))(ToggleButtonGroup);

const allBerries = (berryList) => (
    <>
        {(berryList === 'BLACK' || berryList === 1) && (
            <DrcTooltip tipText="BLACK">
                <span style={!(berryList.includes('BLACK') || berryList.includes(1)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('BLACK')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'BLUE' || berryList === 2) && (
            <DrcTooltip tipText="BLUE">
                <span style={!(berryList.includes('BLUE') || berryList.includes(2)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('BLUE')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'RASP' || berryList === 3) && (
            <DrcTooltip tipText="RASP">
                <span style={!(berryList.includes('RASP') || berryList.includes(3)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('RASP')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'STRAW' || berryList === 4) && (
            <DrcTooltip tipText="STRAW">
                <span style={!(berryList.includes('STRAW') || berryList.includes(4)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('STRAW')}
                </span>
            </DrcTooltip>
        )}
    </>
);

const BerryFormatter = ({ value }) => {
    return value ? allBerries(value) : null;
};

const getColumn = (value, row) => {
    for (let key in row) {
        if (row[key] === value) {
            return key;
        }
    }
};

const cellFormatter = ({ value, row }) => {
    let column = getColumn(value, row);
    if (column === 'LastUpdatedBy') {
        return (
            <span>
                <a style={{ fontWeight: '500', fontSize: '13px', color: '#6f5091', textDecoration: 'none' }} href={'mailto:' + value}>
                    {value}
                </a>
            </span>
        );
    }
    return <span style={{ fontWeight: '500', fontSize: '13px' }}>{value}</span>;
};

const columns = [
    {
        key: 'BerryType',
        name: (
            <DrcTooltip tipText={BERRY_TYPE}>
                <span>{BERRY_TYPE}</span>
            </DrcTooltip>
        ),
        formatter: BerryFormatter,
        filterable: true,
        resizable: true
    },
    {
        key: 'WarehouseNbr',
        name: (
            <DrcTooltip tipText={LOCATION}>
                <span>{LOCATION}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'Variety',
        name: (
            <DrcTooltip tipText={VARIETY}>
                <span>{VARIETY}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'EstimateGroup',
        name: (
            <DrcTooltip tipText={ESTIMATE_GROUP}>
                <span>{ESTIMATE_GROUP}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'Percentage',
        name: (
            <DrcTooltip tipText={QUARTILE}>
                <span>{QUARTILE}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'LastUpdatedBy',
        name: (
            <DrcTooltip tipText={CREATED_BY}>
                <span>{CREATED_BY}</span>
            </DrcTooltip>
        ),
        width: 240,
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    }
];
const DEFAULT_ALL_VALUE = { label: 'All', value: 'All' };
const DEFAULT_ALL = 'All';

const Quartile = (props) => {
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: ''
    });
    const [quartileFilterData, setQuartileFilterData] = useState({
        location: '',
        berryType: '',
        variety: '',
        estimatedGroup: '',
        isQuartileDisabled: true,
        disableLocation: true,
        disableBerryType: true,
        disableVariety: true,
        disableEstimatedGroup: true
    });
    const [dialogContent, setDialogContent] = useState({
        dialogText: '',
        dialogOpen: false
    });
    const [quartileState, setQuartileState] = useState({
        quartile: {
            None: false,
            Q1: false,
            Q2: false
        },
        selectedQuartilePercentage: '',
        isQuartileChanged: false,
        oldValue: '',
        isDisabled: true,
        isButtonModify: false
    });
    const [localManagerLocation, setLocalManagerLocation] = useState([]);

    const [loadingScreen, showLoadingScreen] = useState(false);

    useEffect(() => {
        props.getQuartileByPass([]);
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('managerwarehouses', token, APIEndPoints.GET_LOCAL_MANAGER_WAREHOUSES, 'GET', { Type: 'managerwarehouses' }, null).then(
                (res) => {
                    if (res && res.Data && res.Data.length) {
                        let FilteredLocation = res.Data.filter((item) => item.LocalManager === true);
                        setLocalManagerLocation(FilteredLocation);
                    }
                }
            );
        });
    }, []);

    const getEstimateGroup = (berryType) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'estimatedGroup',
                token,
                APIEndPoints.QUARTILE_ESTIMATED_GROUP(berryType || 'BLACK'),
                'GET',
                { Type: 'estimatedGroup' },
                null
            ).then((res) => {
                if (res.Data.length) {
                    props.setEstimatedGroup(res.Data);
                }
            });
        });
    };

    const getData = (location) => {
        if (location) {
            props.auth.getAccessToken().then((token) => {
                Middleware.Send(
                    PAGE_TYPE,
                    token,
                    APIEndPoints.GET_BERRY_TYPE_BY_WAREHOUSE(location, quartileFilterData.berryType.label),
                    'GET',
                    { Type: PAGE_TYPE },
                    { loadingMessage: 'Fetching quartile pass details...' }
                ).then((res) => {
                    if (res && res.length) {
                        let byPassFilteredData = [];
                        let arr = [];
                        byPassFilteredData = res.filter((item) => {
                            if (item.BerryType === quartileFilterData.berryType.label && item.Variety !== null && item.EstimateGroup !== null) {
                                arr.push(item);
                                return arr;
                            } else {
                                return [];
                            }
                        });
                        props.getQuartileByPass(byPassFilteredData);
                    } else {
                        props.getQuartileByPass([]);
                    }
                });
            });
        }
    };

    const deselectQuartile = () => {
        let selectedQuartilePercentage;
        let quartile = { ...quartileState.quartile };
        for (let key in quartile) {
            quartile[key] = false;
            selectedQuartilePercentage = '';
        }
        setQuartileState({ ...quartileState, quartile, selectedQuartilePercentage, isDisabled: true, isButtonModify: false, oldValue: '' });
    };

    const handleToggleButton = (type) => {
        let quartile = { ...quartileState.quartile };
        let selectedQuartilePercentage, isQuartileChanged;
        for (let key in quartile) {
            if (key === type) {
                quartile[key] = true;
                selectedQuartilePercentage = key;
                if (quartileState.oldValue !== key) {
                    isQuartileChanged = true;
                } else {
                    isQuartileChanged = false;
                }
            } else {
                quartile[key] = false;
            }
        }
        setQuartileState({ ...quartileState, quartile, selectedQuartilePercentage, isQuartileChanged });
    };

    const resetToggleButtons = () => {
        let quartile = { ...quartileState.quartile };
        for (let key in quartile) {
            quartile[key] = false;
        }
        setQuartileState({
            ...quartileState,
            quartile,
            selectedQuartilePercentage: '',
            isDisabled: false,
            isButtonModify: false,
            oldValue: ''
        });
    };

    const handleLocationChange = (option) => {
        let location = option.label.split('-')[0].trim();
        setQuartileFilterData({
            ...quartileFilterData,
            location: option,
            variety: DEFAULT_ALL_VALUE,
            estimatedGroup: DEFAULT_ALL_VALUE,
            disableEstimatedGroup: false,
            disableVariety: false
        });
        deselectQuartile();
        props.setPageDirty(true);
        if (location) {
            getData(location);
            resetToggleButtons();
        }
    };

    const handleBerryTypeChange = (berryType) => {
        setQuartileFilterData({
            ...quartileFilterData,
            berryType: berryType,
            location: '',
            variety: '',
            estimatedGroup: '',
            disableLocation: false,
            disableVariety: true,
            disableEstimatedGroup: true
        });
        deselectQuartile();
        props.getQuartileByPass([]);
        if (berryType) {
            getEstimateGroup(berryType.label);

            props.auth.getAccessToken().then((token) => {
                Middleware.Send(
                    'location',
                    token,
                    APIEndPoints.GET_WARE_HOUSE_BY_BERRY_TYPE(berryType.label),
                    'GET',
                    { Type: 'location' },
                    { loadingMessage: 'Fetching locations...' }
                ).then((data) => {
                    if (data && data.length) {
                        let obj = {};
                        let uniqueData = [];
                        const filteredLocation = data.filter(
                            (item) => item.Variety === null && item.EstimateGroup === null && item.Percentage === 'None'
                        );
                        if (filteredLocation) {
                            for (let i = 0; i < filteredLocation.length; i++) {
                                if (!obj[filteredLocation[i].WarehouseNbr]) {
                                    obj[filteredLocation[i].WarehouseNbr] = true;
                                }
                            }

                            for (let i = 0; i < filteredLocation.length; i++) {
                                if (!obj[filteredLocation[i].WarehouseNbr]) {
                                    obj[filteredLocation[i].WarehouseNbr] = true;
                                }
                            }
                            for (let i = 0; i < localManagerLocation.length; i++) {
                                if (obj[localManagerLocation[i].Code]) {
                                    uniqueData.push({
                                        Code: localManagerLocation[i].Code,
                                        Descript: localManagerLocation[i].Descript
                                    });
                                }
                            }
                            props.filterLocation(uniqueData);
                        }
                    }
                });
            });

            props.auth.getAccessToken().then((token) => {
                Middleware.Send(
                    'variety',
                    token,
                    APIEndPoints.GET_QUARTILE_VARIETY(berryType.label),
                    'GET',
                    { Type: 'variety' },
                    { loadingMessage: 'Fetching...' }
                ).then((res) => {
                    if (res && res.Data && res.Data.length) {
                        props.getVariety(res.Data);
                    }
                });
            });
        }
    };

    const EnableQuartileSelection = (aQuartileByPass, obj) => {
        if ((quartileFilterData.variety || obj['variety']) && (quartileFilterData.estimatedGroup || obj['estimatedGroup'])) {
            let selectedQuartilePercentage;
            if (aQuartileByPass.length === 1) {
                let quartile = { ...quartileState.quartile };
                let QuartileType = aQuartileByPass[0].Percentage;
                if (QuartileType === 'Q1&Q2') {
                    QuartileType = 'Q2';
                }
                for (let key in quartile) {
                    if (key === QuartileType) {
                        quartile[key] = true;
                        selectedQuartilePercentage = key;
                    } else {
                        quartile[key] = false;
                    }
                }

                setQuartileState({
                    ...quartileState,
                    quartile,
                    isDisabled: false,
                    isButtonModify: true,
                    selectedQuartilePercentage,
                    oldValue: QuartileType
                });
            } else {
                resetToggleButtons();
            }
        }
    };
    const handleVarietyChange = (variety) => {
        setQuartileFilterData({
            ...quartileFilterData,
            variety: variety
        });
        let aQuartileByPass;
        if (!quartileFilterData.estimatedGroup || (quartileFilterData.estimatedGroup && quartileFilterData.estimatedGroup.value === DEFAULT_ALL)) {
            aQuartileByPass = props.quartileByPassByLocation.filter((item) =>
                variety.value === DEFAULT_ALL ? true : item.Variety === variety.value
            );
        } else {
            aQuartileByPass = props.quartileByPassByLocation.filter(
                (item) =>
                    (item.Variety === variety.value || variety.value === DEFAULT_ALL) &&
                    item.EstimateGroup === quartileFilterData.estimatedGroup.value
            );
        }
        props.updateQuartileBypass(aQuartileByPass);
        EnableQuartileSelection(aQuartileByPass, { variety: variety });
    };

    const handleEstimatedGroupChange = (estimatedGroup) => {
        setQuartileFilterData({ ...quartileFilterData, estimatedGroup: estimatedGroup });
        let aQuartileByPass;

        if (!quartileFilterData.variety || (quartileFilterData.variety && quartileFilterData.variety.value === DEFAULT_ALL)) {
            aQuartileByPass = props.quartileByPassByLocation.filter((item) =>
                estimatedGroup.value === DEFAULT_ALL ? true : item.EstimateGroup === estimatedGroup.value
            );
        } else {
            aQuartileByPass = props.quartileByPassByLocation.filter(
                (item) =>
                    item.Variety === quartileFilterData.variety.value &&
                    (item.EstimateGroup === estimatedGroup.value || estimatedGroup.value === DEFAULT_ALL)
            );
        }
        props.updateQuartileBypass(aQuartileByPass);
        EnableQuartileSelection(aQuartileByPass, { estimatedGroup: estimatedGroup });
    };

    const resetFilter = () => {
        setQuartileFilterData({
            ...quartileFilterData,
            variety: DEFAULT_ALL_VALUE,
            estimatedGroup: DEFAULT_ALL_VALUE,
            isQuartileDisabled: true,
            disableVariety: false,
            disableEstimatedGroup: false
        });
        deselectQuartile();
    };
    const saveQuartileBypassValues = async () => {
        setDialogContent({
            dialogOpen: false
        });
        let payload = getQuartilePayload(quartileFilterData, quartileState.selectedQuartilePercentage, props.loggedInUser);
        let allInspections;
        let allVarieties = props.variety;
        let allEstimateGroups = props.estimateGroup;
        if (payload.Inspections[0].Variety === 'All') {
            allInspections = allVarieties
                .filter((variety) => variety.value !== 'All')
                .map((variety) => {
                    return {
                        WarehouseNbr: payload.Inspections[0].WarehouseNbr,
                        BerryType: payload.Inspections[0].BerryType,
                        Variety: variety.value,
                        EstimateGroup: payload.Inspections[0].EstimateGroup,
                        Bypass: payload.Inspections[0].Bypass,
                        Percentage: payload.Inspections[0].Percentage,
                        OverallStatus: payload.Inspections[0].OverallStatus
                    };
                });
            payload.Inspections = allInspections;
        }
        allInspections = [];
        if (payload.Inspections[0].EstimateGroup === 'All') {
            payload.Inspections.forEach((inspection) => {
                allEstimateGroups
                    .filter((estimateGroup) => estimateGroup.value !== 'All')
                    .map((estimateGroup) => {
                        return allInspections.push({
                            WarehouseNbr: inspection.WarehouseNbr,
                            BerryType: inspection.BerryType,
                            EstimateGroup: estimateGroup.value,
                            Variety: inspection.Variety,
                            Bypass: inspection.Bypass,
                            Percentage: inspection.Percentage,
                            OverallStatus: inspection.OverallStatus
                        });
                    });
            });
            payload.Inspections = allInspections;
        }
        try {
            showLoadingScreen(true);
            let token = await props.auth.getAccessToken();
            let data = await QuartileBatchProcess.batchProcess(PAGE_TYPE, token, APIEndPoints.POST_INSPECTION_BYPASS, 'POST', payload, {
                showLoadingScreen: () => {
                    /**/
                },
                hideLoadingScreenAction: () => {
                    /**/
                }
            });
            showLoadingScreen(false);
            props.setPageDirty(false);
            setMessageDialog({
                showMessage: true,
                severity: data.Message ? 'success' : 'error',
                message: data.Message || 'Something happened. but I am not sure if it is good or bad!'
            });
            resetFilter();
            getData(quartileFilterData.location.value);
        } catch (error) {
            showLoadingScreen(false);
            props.setPageDirty(false);
            let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
            setMessageDialog({
                showMessage: true,
                severity: 'error',
                message: message
            });
        }
    };

    const handleAdd = () => {
        let rows = quartileDialogGridData(quartileFilterData, quartileState.selectedQuartilePercentage, props.loggedInUser);
        rows.Variety = quartileFilterData.variety.value;
        let title = quartileState.isButtonModify ? QUARTILE_MODIFY : QUARTILE_ADD;
        let dialogText = [
            <div style={{ fontSize: 20, marginBottom: 10 }}>{title}</div>,
            <DrcGrid rows={[rows]} columns={columns} minHeight={90} hideCount={true} />
        ];
        setDialogContent({
            dialogText: dialogText,
            dialogOpen: true
        });
    };

    const onDialogNo = () => {
        setDialogContent({
            dialogOpen: false
        });
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    const { classes } = props;
    return (
        <DrcPanel style={{ maxWidth: '100%' }}>
            <DrcPanel style={{ maxWidth: '100%' }}>
                <Grid container>
                    <Grid container spacing={3} justify="space-between" alignItems="flex-start">
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <BerryTypeSelect
                                required
                                value={quartileFilterData.berryType}
                                onChange={handleBerryTypeChange}
                                options={props.berryType}
                            ></BerryTypeSelect>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcSelect
                                required
                                label={LOCATION}
                                placeholder={LOCATION_PLACEHOLDER}
                                value={quartileFilterData.location}
                                options={props.locationList}
                                onChange={handleLocationChange}
                                isDisabled={quartileFilterData.disableLocation}
                            ></DrcSelect>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcSelect
                                label={VARIETY}
                                placeholder={VARIETY_PLACEHOLDER}
                                value={quartileFilterData.variety}
                                options={props.variety}
                                onChange={handleVarietyChange}
                                isDisabled={quartileFilterData.disableVariety}
                            ></DrcSelect>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcSelect
                                label={ESTIMATE_GROUP}
                                placeholder={ESTIMATE_GROUP_PLACEHOLDER}
                                value={quartileFilterData.estimatedGroup}
                                options={props.estimateGroup}
                                onChange={handleEstimatedGroupChange}
                                isDisabled={quartileFilterData.disableEstimatedGroup}
                            ></DrcSelect>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justify="space-between">
                        <Grid item xs={6} sm={6} md={9} lg={9} alignItems="center">
                            <StyledToggleButtonGroup value="left" exclusive aria-label="text alignment" floatRight style={{ paddingTop: '8px' }}>
                                <ToggleButton
                                    selected={quartileState.quartile.None}
                                    disabled={quartileState.isDisabled}
                                    aria-label="left aligned"
                                    style={{
                                        width: '110px'
                                    }}
                                    className={classes.radioButton}
                                    onClick={() => handleToggleButton('None')}
                                >
                                    None
                                </ToggleButton>
                                <ToggleButton
                                    selected={quartileState.quartile.Q1}
                                    disabled={quartileState.isDisabled}
                                    aria-label="centered"
                                    onClick={() => handleToggleButton('Q1')}
                                >
                                    Q1
                                </ToggleButton>
                                <ToggleButton
                                    selected={quartileState.quartile.Q2}
                                    disabled={quartileState.isDisabled}
                                    aria-label="right aligned"
                                    onClick={() => handleToggleButton('Q2')}
                                >
                                    Q1&Q2
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3} alignItems="center">
                            <DrcButton
                                disabled={
                                    !quartileFilterData.location ||
                                    !quartileFilterData.berryType ||
                                    !quartileFilterData.variety ||
                                    !quartileFilterData.estimatedGroup ||
                                    !quartileState.selectedQuartilePercentage ||
                                    !quartileState.isQuartileChanged
                                }
                                isPrimary
                                floatRight
                                onClick={handleAdd}
                            >
                                {quartileState.isButtonModify ? MODIFY : OVERRIDE_ADD}
                            </DrcButton>
                        </Grid>
                    </Grid>
                </Grid>
            </DrcPanel>
            <div>
                <h2 className={classes.gridTitle}>Quartile Bypass</h2>
                <QuartileByPassTable columns={columns}></QuartileByPassTable>
            </div>
            <DrcDialog
                className={classes.dialog}
                title={dialogContent.dialogText}
                open={dialogContent.dialogOpen}
                buttons={
                    <React.Fragment>
                        <DrcButton isPrimary onClick={saveQuartileBypassValues}>
                            {ConfirmAcceptBtn}
                        </DrcButton>
                        <DrcButton isSecondary onClick={onDialogNo}>
                            {ConfirmRejectBtn}
                        </DrcButton>
                    </React.Fragment>
                }
            />
            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
            <DrcBackdrop
                isLoading
                show={loadingScreen}
                loadingMessage={quartileState.isButtonModify ? 'Updating quartile bypass...' : 'Adding quartile bypass...'}
            />
        </DrcPanel>
    );
};

const mapStateToProps = (state) => {
    return {
        berryType: state.pointMatrixReducer.berryType,
        variety: state.quartileReducer.variety,
        estimateGroup: state.quartileReducer.estimateGroup,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        quartileByPassByLocation: state.quartileReducer.quartileByPassByLocation,
        loggedInUser: state.masterReducer.loggedInUser,
        locationList: state.quartileReducer.location
    };
};

const mapDispatchToProps = (dispatch) => ({
    showLoadingScreenAction: (title) => dispatch(showLoadingScreenAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    setPageDirty: (isDirty) => dispatch(setPageDirty(isDirty)),
    setBerryType: (result) => dispatch(setBerryType(result)),
    getQuartileByPass: (data) => dispatch(getQuartileByPass(data)),
    updateQuartileBypass: (data, switchType) => dispatch(updateQuartileBypass(data, switchType)),
    getVariety: (data) => dispatch(getVariety(data)),
    filterLocation: (data) => dispatch(filterLocation(data)),
    setEstimatedGroup: (data) => dispatch(setEstimatedGroup(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withAuth(withStyles(styles)(Quartile))));
