import React from 'react';
import TabRouting from '../../components/TabRouting';

const selectedMainTabIndex = 7;
const selectedSubTabIndex = 0;
const DispositionOptionsMain = () => {
    return <TabRouting selectedMainTabIndex={selectedMainTabIndex} selectedSubTabIndex={selectedSubTabIndex}></TabRouting>;
};

export default DispositionOptionsMain;
