import { DrcInput, DrcPanel, DrcButton, DrcDialog, DrcSelect } from 'driscolls-react-components';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import APIEndPoints from '../../services/api';
import { withAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import LcCheckBox from '../../components/extended/LcCheckBox';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { setPageDirty } from '../../actions/actions';
import { setBerryType } from '../../actions/PointMatrixAction';
import { validateRanchNumber } from '../../util/validation';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { BERRY_TYPE, BERRY_TYPE_PLACEHOLDER } from '../../language/english';

let styles = (theme) => ({
    gridTitle: {
        marginTop: '0px',
        marginBottom: '8px',
        fontSize: '21px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#4a773c'
    },
    gridCount: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#6f5091;'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        width: '100%',
        height: 'auto',

        maxHeight: '200px'
    },
    checkLabel: {
        fontSize: '1.3rem',
        display: 'inline-block',
        marginBottom: '2px'
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '108px',
        paddingRight: '2px',
        marginRight: '2px'
    },
    layout: {
        width: '97%'
    }
});

const PAGE_TYPE = 'location';
let localmanagerwarehouses = [];
let currentToken;
let changedData = [];
let changingLabel = '';
let currentSearch = '';

const Location = (props) => {
    const [warehousedata, setWareHouseData] = useState([]);
    const [filteredWarehousedata, setFilteredWareHouseData] = useState([]);
    const [selectedBerry, setSelectedBerry] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [showDialog, setShowDialog] = useState({ show: false, message: '' });
    const [screenHasDataChanges, setScreenHasDataChanges] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        currentSearch = '';
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('berryType', token, APIEndPoints.GET_BERRY_TYPE, 'GET', { Type: 'berryType' }, null).then((res) => {
                if (res.Results.length) {
                    props.setBerryType(res.Results);
                    setSelectedBerry({ value: res.Results[0].CommodityId, label: res.Results[0].CommodityCode });
                    getData(res.Results[0].CommodityCode, true);
                }
            });
        });
    }, []);

    const getSelectionState = (overrideData, item) => {
        let matches = overrideData.filter((dataitem) => dataitem.WarehouseNbr === item.Code);
        let displayables = [];
        if (matches && matches.length > 0) {
            displayables = matches.filter((dataitem) => {
                return dataitem.Bypass && dataitem.Percentage === 'None' && !dataitem.Variety && !dataitem.EstimateGroup;
            });
            return displayables.length > 0; //at least one match should fit the criteria
        }
        return false;
    };

    const getData = async (berrytype, defaultLoadingMessage) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'warehouses',
                token,
                APIEndPoints.GET_LOCAL_MANAGER_WAREHOUSES,
                'GET',
                { Type: PAGE_TYPE },
                { overrideResponseMapping: false, loadingMessage: defaultLoadingMessage ? 'Loading' : 'Fetching locations...' }
            )
                .then((data) => {
                    localmanagerwarehouses = sortOrder(data.Results);
                    getSavedLocationBypassData(berrytype, defaultLoadingMessage, token);
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setShowDialog({
                        show: true,
                        severity: 'error',
                        message: message
                    });
                });
        });
    };

    const sortOrder = (data) => {
        return data.sort((a, b) => {
            return a.LocalManager === b.LocalManager ? 0 : a.LocalManager ? -1 : 1;
        });
    };

    const getSavedLocationBypassData = async (berrytype, defaultLoadingMessage, token) => {
        Middleware.Send(
            PAGE_TYPE,
            token,
            APIEndPoints.GET_WARE_HOUSE_BY_BERRY_TYPE(berrytype),
            'GET',
            { Type: PAGE_TYPE },
            { loadingMessage: defaultLoadingMessage ? 'Loading' : 'Fetching saved locations...' }
        ).then((overridedata) => {
            currentToken = token;
            changingLabel = '';
            let selectionProp = berrytype + '_selected';
            if (localmanagerwarehouses) {
                localmanagerwarehouses.forEach((item) => Object.assign(item, { [selectionProp]: getSelectionState(overridedata, item) }));
                setWareHouseData(JSON.parse(JSON.stringify(localmanagerwarehouses)));
                setFilteredWareHouseData(JSON.parse(JSON.stringify(localmanagerwarehouses)));
                searchWarehouses();
            }
        });
    };

    const handleBerryTypeChange = (event) => {
        if (event.label !== selectedBerry.label) {
            if (saveEnabled) {
                changingLabel = event.label;
                setScreenHasDataChanges(true);
                return;
            }
            setSelectedBerry(event);
            getData(event.label);
        }
    };

    const handleOnChangeSearch = (event) => {
        let isValid = validateRanchNumber(event);
        let val = event.target.value;
        if (!isValid) {
            let modSearchValue = val.slice(0, val.length - 1);
            setSearchValue(modSearchValue);
        } else {
            currentSearch = event.target.value;
            setSearchValue(currentSearch);
            searchWarehouses();
        }
    };

    const onPasteInToSearch = async (_event) => {
        try {
            let val = await navigator.clipboard.readText();
            if (isNaN(val)) {
                let modSearchValue = '';
                setSearchValue(modSearchValue);
            } else {
                currentSearch = val;
                setSearchValue(currentSearch);
                searchWarehouses();
            }
        } catch (e) {
            console.error('This is not supported');
        }
    };

    const searchWarehouses = () => {
        if (currentSearch && currentSearch !== '') {
            let filteredData = localmanagerwarehouses.filter((item) => item.Code.toString().indexOf(currentSearch) > -1);
            setFilteredWareHouseData(filteredData);
        } else {
            setFilteredWareHouseData(localmanagerwarehouses);
        }
    };

    const onCheckChange = (_event, selected, id) => {
        let ranchId = id;
        let status = checkIfWarehouseStateIsChanged(id, selected);
        let selectionProp = selectedBerry.label + '_selected';
        if (status) {
            changedData.push({ id: id, [selectionProp]: selected });
        } else {
            changedData = changedData.filter((item) => item.id !== id);
        }
        setSaveStatus(changedData.length > 0);
        let filteredData = filteredWarehousedata.map((item) =>
            item.Code === ranchId ? Object.assign(item, { [selectedBerry.label + '_selected']: selected }) : Object.assign(item)
        );
        setFilteredWareHouseData(filteredData);
    };

    const checkIfWarehouseStateIsChanged = (id, status) => {
        return warehousedata.some((item) => item.Code === id && item[selectedBerry.label + '_selected'] !== status);
    };

    //Updates the local warehouse data so that the saved state is now the default
    const updateLocalData = () => {
        changedData.forEach((item) => {
            warehousedata.forEach((whsitem) => {
                if (whsitem.Code === item.id) {
                    whsitem[selectedBerry.label + '_selected'] = item[selectedBerry.label + '_selected'];
                }
            });
        });
    };

    const saveLocationBypassValues = () => {
        const bypassedWwareHouses = changedData.map((item) =>
            Object.assign(
                {},
                { WarehouseNbr: item.id },
                { Bypass: item[selectedBerry.label + '_selected'] },
                { BerryType: selectedBerry.label },
                { Percentage: 'None' }
            )
        );
        const loggedInUser = DuAuthenticationUtilities.GetEmail(currentToken);
        const currentTime = new Date().toISOString();
        const payload = {
            Inspections: bypassedWwareHouses,
            LastUpdatedBy: loggedInUser,
            LastUpdatedDateTime: currentTime
        };
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('PoUpdate', token, APIEndPoints.POST_WARE_HOUSE_LOCATION_BYPASS, 'POST', payload, {
                overrideResponseMapping: true,
                loadingMessage: 'Updating location bypass...'
            })
                .then((data) => {
                    setScreenHasDataChanges(false);
                    updateLocalData();
                    changedData = [];
                    setSaveStatus(false);
                    setShowDialog({
                        show: true,
                        severity: data.Message ? 'success' : 'error',
                        message: data.Message || 'Something happened. but I am not sure if it is good or bad!'
                    });
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setShowDialog({
                        show: true,
                        severity: 'error',
                        message: message
                    });
                });
        });
    };

    const handleConfirmReject = () => {
        setScreenHasDataChanges(false);
        changedData = [];
        setSaveStatus(false);
        setSelectedBerry(changingLabel);
        getData(changingLabel);
    };

    const setSaveStatus = (status) => {
        setSaveEnabled(status);
        props.setPageDirty(status);
    };

    const handleCancelNavigation = () => {
        setScreenHasDataChanges(false);
    };

    const handleClose = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowDialog({ open: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    return (
        <div>
            <DrcPanel style={{ maxWidth: '100%' }}>
                <DrcPanel style={{ maxWidth: '100%', border: 'none', paddingLeft: '0px', marginBottom: '0px', paddingBottom: '0px' }}>
                    <div className="row">
                        <div className="col-xs-9 col-md-7" style={{ maxWidth: '200px' }}>
                            {/*<BerryTypeSelect value={selectedBerry} onChange={handleBerryTypeChange} options={props.berryType}></BerryTypeSelect> */}
                            {
                                <DrcSelect
                                    label={BERRY_TYPE}
                                    placeholder={BERRY_TYPE_PLACEHOLDER}
                                    value={selectedBerry}
                                    options={props.berryType}
                                    onChange={handleBerryTypeChange}
                                ></DrcSelect>
                            }
                        </div>
                    </div>
                </DrcPanel>
                {filteredWarehousedata && (
                    <div>
                        <div className="row">
                            <div className="col-xs-11 col-md-11">
                                <h2 className={props.classes.gridTitle}>Locations</h2>
                            </div>
                            <div className="col-xs-1 col-md-1">
                                <DrcButton disabled={!saveEnabled} isPrimary floatRight onClick={() => saveLocationBypassValues()}>
                                    Save
                                </DrcButton>
                            </div>
                        </div>
                        <DrcPanel style={{ maxWidth: '100%', margin: '0px auto', paddingBottom: '10px' }}>
                            <div className="row">
                                <div className="col-xs-2 col-md-2">
                                    <DrcInput
                                        placeholder="Search"
                                        value={searchValue}
                                        onChange={(event) => {
                                            handleOnChangeSearch(event);
                                        }}
                                        onPaste={(event) => {
                                            onPasteInToSearch(event);
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>
                            {filteredWarehousedata.length > 0 ? (
                                <GridList
                                    cellHeight={40}
                                    cellWidth={250}
                                    className={props.classes.gridList}
                                    cols={10}
                                    style={{ maxHeight: Math.max(window.innerHeight - 480, 195) }}
                                >
                                    {filteredWarehousedata.map((tile) => (
                                        <GridListTile key={tile.Code} cols={1}>
                                            <div
                                                className={props.classes.checkboxContainer}
                                                // style={{ cursor: !tile.LocalManager ? 'not-allowed' : 'pointer',backgroundColor: !tile.LocalManager?'#ff':'#6f509140',borderStyle:!tile.LocalManager?'none':'solid' }}
                                                style={{ cursor: !tile.LocalManager ? 'not-allowed' : 'pointer' }}
                                                title={tile.Description + (!tile.LocalManager ? ' - You do not have access to this location' : '')}
                                            >
                                                <LcCheckBox
                                                    disabled={!tile.LocalManager}
                                                    name={'check_' + tile.Code}
                                                    checked={tile[selectedBerry.label + '_selected'] ? true : false}
                                                    onSelectionChange={(event, selected, id) => {
                                                        onCheckChange(event, selected, id);
                                                    }}
                                                />
                                                <span
                                                    className={props.classes.checkLabel}
                                                    style={{
                                                        color: !tile.LocalManager ? 'rgba(0, 0, 0, 0.7)' : '#6f5091c7',
                                                        fontWeight: !tile.LocalManager ? 'normal' : '500'
                                                    }}
                                                >
                                                    {tile.Code}
                                                </span>
                                            </div>
                                        </GridListTile>
                                    ))}
                                </GridList>
                            ) : (
                                <div>No Results</div>
                            )}
                            {screenHasDataChanges && (
                                <DrcDialog
                                    open={screenHasDataChanges}
                                    buttons={
                                        <React.Fragment>
                                            <DrcButton isPrimary floatRight onClick={handleConfirmReject}>
                                                Leave this page
                                            </DrcButton>
                                            <DrcButton isSecondary floatRight onClick={handleCancelNavigation}>
                                                Stay on this page
                                            </DrcButton>
                                        </React.Fragment>
                                    }
                                    title={`Unsaved changes`}
                                >
                                    <p>You have unsaved changes that will be lost if you leave this page.</p>
                                    <p style={{ paddingBottom: '12px' }}>Are you sure you want to leave?</p>
                                </DrcDialog>
                            )}
                        </DrcPanel>
                    </div>
                )}
            </DrcPanel>
            <Snackbar open={showDialog.show} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={showDialog.severity}>
                    {showDialog.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        warehouses: state.masterReducer.warehouses,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        berryType: state.pointMatrixReducer.berryType
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageDirty: (isDirty) => dispatch(setPageDirty(isDirty)),
    setBerryType: (result) => dispatch(setBerryType(result))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withAuth(withStyles(styles)(Location))));
