import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import withStyles from '@material-ui/styles/withStyles';
import { DrcMain } from 'driscolls-react-components';
import DrcTab from './DrcTab/DrcTab';
import { setPageTitleAction, setPageDirty } from '../actions/actions';
import PointMatrixCreateTab from '../pages/PointMatrix/PointMatrix';
import { handleRouting, routingOnTabChange } from '../util/navigation';
import DirtyCheck from './DirtyCheck';
import Override from '../pages/Override/Override';
import Notification from '../pages/Notification/Notification';
import Bypass from '../pages/Bypass/Bypass';
import DistrictGroup from '../pages/DistrictGroup/DistrictGroup';
import ManagerMaintenance from '../pages/Maintenance/ManagerMaintenance';
import MasterDataUtilities from '../data/MasterDataUtilities';
import Disposition from '../pages/Disposition/Disposition';
import RejectionThreshold from '../pages/RejectionThreshold/RejectionThreshold';
import ProducingAreaRegion from '../pages/ProducingAreaRegion/ProducingAreaRegion';
import SummaryStatistics from '../pages/SummaryStatistics/SummaryStatistics';

let styles = () => ({
    main: {
        paddingBottom: 0
    }
});

let changingTabIndex = 0;
let currentIndex = 0;
const TabRouting = (props) => {
    const [screenHasDataChanges, setScreenHasDataChanges] = useState(false);

    useEffect(() => {
        let pageTitle = handleRouting(props.match.path);
        props.setPageTitle(pageTitle);
    }, [props.match.path]);

    const handleConfirmReject = () => {
        currentIndex = changingTabIndex;
        setScreenHasDataChanges(false);
        setPageDirty(false);
        continueNavigation();
    };

    const handleMainTabChange = (index) => {
        changingTabIndex = index;
        if (props.isPageDirty) {
            setScreenHasDataChanges(true);
            return;
        }
        changingTabIndex = index;
        currentIndex = index;
        continueNavigation();
    };
    const continueNavigation = () => {
        let title = routingOnTabChange(changingTabIndex, props.history);
        setPageTitle(title);
    };

    const handleCancelNavigation = () => {
        setScreenHasDataChanges(false);
    };

    const renderSwitch = () => {
        switch (selectedMainTabIndex) {
            case 0:
                return <Bypass subTabItems={tabs.ByPassTabs.Bypass} selectedSubTabIndex={selectedSubTabIndex}></Bypass>;
            case 1:
                return <Override></Override>;

            case 2:
                return <Notification></Notification>;

            case 3:
                return (
                    <PointMatrixCreateTab subTabItems={tabs.pointMatrixTabs.Matrix} selectedSubTabIndex={selectedSubTabIndex}></PointMatrixCreateTab>
                );
            case 4:
                return <ManagerMaintenance></ManagerMaintenance>;
            case 5:
                return <DistrictGroup></DistrictGroup>;

            case 6:
                return <RejectionThreshold></RejectionThreshold>;

            case 7:
                return <Disposition subTabItems={tabs.DispositionTabs.Disposition} selectedSubTabIndex={selectedSubTabIndex}></Disposition>;
            case 8:
                return <ProducingAreaRegion></ProducingAreaRegion>;
            case 9:
                return <SummaryStatistics></SummaryStatistics>;
            default:
                return;
        }
    };

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    const { setPageTitle, selectedMainTabIndex, selectedSubTabIndex, tabs } = props;
    return (
        <DrcMain transparent className={props.classes.main} style={{ paddingBottom: 0, overflow: 'auto' }}>
            {!!Object.keys(tabs).length && (
                <DrcTab
                    selectedMenuIndex={selectedMainTabIndex}
                    menuItems={tabs.mainTabs}
                    handleMenuItemChange={handleMainTabChange}
                    centered
                ></DrcTab>
            )}
            {renderSwitch()}
            {screenHasDataChanges && (
                <DirtyCheck
                    screenHasDataChanges={screenHasDataChanges}
                    handleConfirmReject={handleConfirmReject}
                    handleCancelNavigation={handleCancelNavigation}
                ></DirtyCheck>
            )}
        </DrcMain>
    );
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isPageDirty: state.rootReducer.isPageDirty,
        tabs: state.masterReducer.tabs
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setPageDirty: (isDirty) => dispatch(setPageDirty(isDirty))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(TabRouting))));
