import { Middleware } from 'one-ring';

const RejectionThresholdMapping = [
    Middleware.CreateMapping('T_RanchBypassOverrideId', 'T_RanchBypassOverrideId'),
    Middleware.CreateMapping('ProducingArea', 'ProducingArea'),
    Middleware.CreateMapping('RanchNbr', 'RanchNbr'),
    Middleware.CreateMapping('EndDate', 'EndDate'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('RanchName', 'RanchName'),
    Middleware.CreateMapping('StartDate', 'StartDate'),
    Middleware.CreateArrayMapping('Results', 'Data')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('RejectionThreshold', 'GET')) {
        Middleware.RegisterMapping('RejectionThreshold', 'GET', RejectionThresholdMapping);
        Middleware.RegisterMapping('RejectionThreshold', 'POST', RejectionThresholdMapping);
    }
  
}

const RejectionThresholdMappingTypesGroup = {
    ConfigureMiddleWare
};

export default RejectionThresholdMappingTypesGroup;
