import { GET_GROWER_LIST, GET_FACTOR_TYPE_LIST, GET_GROWER_PREFERENCES_LIST, GET_RANCH_LIST } from '../Constant/ActionConstant';

const initialState = {
    GrowerList: [],
    RachList: [],
    FactorTypeList: [],
    GrowerPreferencesList: []

};

const growerPreferencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GROWER_LIST:

            let Grower = action.payload.map((item) => {
                return { value: item.GrowerNbr, label: item.GrowerNbr + '-' + item.GrowerName };
            });
            return { ...state, GrowerList: Grower };
        case GET_GROWER_PREFERENCES_LIST:
            return { ...state, GrowerPreferencesList: action.payload };
        case GET_RANCH_LIST:
            return { ...state, RachList: action.payload };
        case GET_FACTOR_TYPE_LIST:
            return { ...state, FactorTypeList: action.payload };
        default:
            return state;
    }
};

export default growerPreferencesReducer;
