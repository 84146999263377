import { GET_PRODUCING_AREA_REGION, USER_PRODUCING_AREA, M_LOOKUP_LIST_DATA } from '../Constant/ActionConstant';

const initialState = {
    producinaAreaRegionList: [],
    userProducingArea: [],
    mLookupList: []
};
const ProducingAreaRegionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCING_AREA_REGION:
            return { ...state, producinaAreaRegionList: action.payload };
        case USER_PRODUCING_AREA:
            let userProducingArea = action.payload.map((item) => {
                return { value: item.ProducingArea, label: item.ProducingAreaCode };
            });
            return { ...state, userProducingArea: userProducingArea };
        case M_LOOKUP_LIST_DATA:
            let mlookup = action.payload.map((item) => {
                return { value: item.LookupCode, label: item.LookupCode };
            });
            return { ...state, mLookupList: mlookup };

        default:
            return state;
    }
};

export default ProducingAreaRegionReducer;
