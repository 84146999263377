export const RANCH_OVERRIDE_CREATE = 'Ranch Override created successfully';
export const RANCH_OVERRIDE_UPDATE = 'Ranch Override updated successfully';
export const RANCH_OVERRIDE_DELETE = 'Ranch Override deleted successfully';
export const QUARTILE_SAVE_ERROR = 'Something happened, but I am not sure if it is good or bad.';
export const QUARTILE_SAVE = 'Request updated successfully';
export const NOTIFICATION_CREATE = 'Notification created successfully';
export const NOTIFICATION_UPDATE = 'Notification updated successfully';
export const NOTIFICATION_DELETE = 'Notification deleted successfully';
export const PRODUCINGAREAREGIONMAPPING_DELETE = 'Producing Area Region Mapping deleted successfully';
export const MAINTENANCE_DELETE = 'Assigned producing area deleted successfully';
export const QUARTILE_MODIFY = 'Are you sure you want to modify Quartile bypass?';
export const QUARTILE_ADD = 'Are you sure you want to add new Quartile bypass?';
export const LOCATION = 'Location';
export const LOCATION_PLACEHOLDER = 'Please select Location';
export const PRODUCING_AREA = 'Producing Area';
export const PRODUCING_AREA_PLACEHOLDER = 'Please select Producing Area';
export const DISTRICT_GROUP = 'QMP District Group';
export const DISTRICT_GROUP_PLACEHOLDER = 'Please select District Group';
export const RANCH_NUMBER = 'Ranch Nbr';
export const RANCH_NUMBER_PLACEHOLDER = 'Please select Ranch Nbr';
export const RANCH_NAME = 'Ranch Name';
export const START_DATE = 'Start Date';
export const START_DATE_PLACEHOLDER = 'Enter Start Date';
export const END_DATE = 'End Date';
export const END_DATE_PLACEHOLDER = 'Enter End Date';
export const OVERRIDE_ADD = 'Add';
export const CREATED_BY = 'Created By';
export const MODIFIED_BY = 'Modified By';
export const BERRY_TYPE = 'Berry Type';
export const BERRY_TYPE_PLACEHOLDER = 'Please select Berry Type';
export const LABEL = 'Label';
export const LABEL_PLACEHOLDER = 'Please select Label';
export const VARIETY = 'Variety';
export const VARIETY_PLACEHOLDER = 'Please select variety';
export const POOL_WEEK_RANGE = 'Pool Week Range';
export const POOL_WEEK_RANGE_PLACEHOLDER = 'Default';
export const MIN_POINTS = 'Min Points';
export const MAX_POINTS = 'Max Points';
export const SPREAD = 'Spread';
export const SCORE = 'Score';
export const POINT = 'Point';
export const MATRIX_SEARCH = 'Search';
export const PRODUCT_ATTRIBUTE = 'Product Attribute';
export const POOL_WEEK = 'PoolWeek';
export const PREVIEW = 'PREVIEW';
export const CONFIRM = 'CONFIRM';
export const CANCEL = 'CANCEL';
export const VARIETY_DESCRIPTION = 'Variety Description';
export const ESTIMATE_GROUP = 'Estimate Group';
export const ESTIMATE_GROUP_PLACEHOLDER = 'Please select estimate group';
export const QUARTILE = 'Quartile';
export const MODIFY = 'Modify';
export const STATUS = 'Status';
export const ConfirmAcceptBtn = 'Accept';
export const ConfirmRejectBtn = 'Oops, Just Kidding';
export const SAVE = 'Save';
export const MODIFIED_DATE_TIME = 'Modified Date Time';
export const CREATED_DATE_TIME = 'Created Date Time';
export const ACTION = 'Action';
export const GROWING_DISTRICT = 'Growing District';
export const ACTIVE = 'Active';
export const DISTRICTGROUP_MAPPING_ADDED = 'Districtgroup mapping created successfully';
export const DISTRICTGROUP_MAPPING_DELETED = 'Districtgroup mapping deleted successfully';
export const WARE_HOUSE = 'Ware House';
export const FACTOR_TYPE = 'Factor Type';
export const DONATION = 'DONATION';
export const PROCESSING = 'PROCESSING';
export const PICKUP = 'PICKUP';
export const GROWER_NAME = 'Grower Name';
export const GROWER_NAME_PLACEHOLDER = 'Please select Grower';
export const FACTOR_TYPE_PLACEHOLDER = 'Please select factor type';
