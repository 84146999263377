export const PRODUCING_AREA = 'PRODUCING_AREA';
export const DISTRICT_GROUP = 'DISTRICT_GROUP';
export const RANCH_DETAILS = 'RANCH_DETAILS';
export const SAVED_RANCH_DETAILS = 'SAVED_RANCH_DETAILS';
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';
export const RESET_UPDATE_QUARTILE_DATA = 'RESET_UPDATE_QUARTILE_DATA';
export const UPDATE_QUARTILE_BYPASS = 'UPDATE_QUARTILE_BYPASS';
export const SET_QUARTILE_BYPASS = 'SET_QUARTILE_BYPASS';
export const BERRY_TYPE = 'BERRY_TYPE';
export const VARIETY = 'VARIETY';
export const LABEL = 'LABEL';
export const LOOK_UP_VALUES = 'LOOK_UP_VALUES';
export const POINT_MATRIX_PREVIEW_DATA = 'POINT_MATRIX_PREVIEW_DATA';
export const IS_HEADER_RESET = 'IS_HEADER_RESET';
export const GET_QUARTILE_BYPASS = 'GET_QUARTILE_BYPASS';
export const RESET_SELECTED_INDEX = 'RESET_SELECTED_INDEX';
export const GET_VARIETY = 'GET_VARIETY';
export const ESTIMATED_GROUP = 'ESTIMATED_GROUP';
export const ENABLE_FIRST_ROW_SELECTION = 'ENABLE_FIRST_ROW_SELECTION';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const SET_USER_GROUP = 'SET_USER_GROUP';
export const MANAGER_MAINTENANCE_DATA = 'MANAGER_MAINTENANCE_DATA';
export const LOCATION_LIST = 'LOCATION_LIST';
export const USER_PRODUCING_AREA = 'USER_PRODUCING_AREA';
export const DISPOSITION_DETAILS = 'DISPOSITION_DETAILS';
export const SAVED_DISPOSITION_DETAILS = 'SAVED_DISPOSITION_DETAILS';
export const THRESHOLD_LIST_DATA = 'THRESHOLD_LIST_DATA';
export const USER_PRODUCING_AREA_THRESHOLD = 'USER_PRODUCING_AREA_THRESHOLD';
export const M_LOOKUP_LIST_DATA = 'M_LOOKUP_LIST_DATA';
export const FACTOR_TYPE = 'FACTOR_TYPE';
export const GET_GROWER_LIST = 'GET_GROWER_LIST';
export const GET_GROWER_PREFERENCES_LIST = 'GET_GROWER_PREFERENCES_LIST';
export const GET_RANCH_LIST = 'GET_RANCH_LIST';
export const GET_FACTOR_TYPE_LIST = 'GET_FACTOR_TYPE_LIST';
export const GET_PRODUCING_AREA_REGION = 'GET_PRODUCING_AREA_REGION';
export const SUMMARY_LIST = 'SUMMARY_LIST';
