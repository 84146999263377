import { Middleware } from 'one-ring';

const GrowerPreferencesMapping = [
    Middleware.CreateMapping('T_RanchBypassOverrideId', 'T_RanchBypassOverrideId'),
    Middleware.CreateMapping('ProducingArea', 'ProducingArea'),
    Middleware.CreateMapping('RanchNbr', 'RanchNbr'),
    Middleware.CreateMapping('EndDate', 'EndDate'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('RanchName', 'RanchName'),
    Middleware.CreateMapping('StartDate', 'StartDate'),
    Middleware.CreateArrayMapping('Disposition', 'Disposition')
];

const GrowerMapping = [
    Middleware.CreateMapping('GrowerNbr', 'GrowerNbr'),
    Middleware.CreateMapping('GrowerName', 'GrowerName'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('GrowerPreferences', 'GET')) {
        Middleware.RegisterMapping('GrowerPreferences', 'GET', GrowerPreferencesMapping);
        Middleware.RegisterMapping('GrowerPreferences', 'POST', GrowerPreferencesMapping);
    }
    if (!Middleware.CheckMappingExists('Grower', 'GET')) {
        Middleware.RegisterMapping('Grower', 'GET', GrowerMapping);
        Middleware.RegisterMapping('Grower', 'POST', GrowerMapping);
    }
}

const GrowerPreferenceseMappingTypesGroup = {
    ConfigureMiddleWare
};

export default GrowerPreferenceseMappingTypesGroup;
