export const ManagerTabs = {
    mainTabs: [
        { label: 'BYPASS', to: '/Bypass/Location/' },
        { label: 'OVERRIDE', to: '/Override/' },
        { label: 'NOTIFICATIONS', to: '/Notification/' },
        { label: 'REJECTION THRESHOLD', to: '/RejectionThreshold/' },
        { label: 'DISPOSITION', to: '/Disposition/DispositionOptions/' },
        { label: 'PRODUCING AREA REGION', to: '/ProducingAreaRegion/' },
        { label: 'SUMMARY STATISTICS ', to: '/SummaryStatistics/' }
    ],
    ByPassTabs: {
        Bypass: [
            { label: 'Location', to: '/Bypass/Location/' },
            { label: 'Quartile', to: '/Bypass/Quartile/' }
        ]
    },
    DispositionTabs: {
        Disposition: [
            { label: 'Disposition Options', to: '/Disposition/DispositionOptions/' },
            { label: 'Grower Preferences', to: '/Disposition/GrowerPreferences/' }
        ]
    }
};

export const AnalystTabs = {
    mainTabs: [
        // { label: 'NOTIFICATIONS', to: '/Notification/' },
        { label: 'POINT MATRIX', to: '/PointMatrix/Create/' }
    ],
    pointMatrixTabs: {
        Matrix: [
            { label: 'Create', to: '/PointMatrix/Create/' },
            { label: 'Preview', to: '/PointMatrix/Preview/' }
        ]
    }
};

export const AdminTabs = {
    mainTabs: [
        { label: 'BYPASS', to: '/Bypass/Location/' },
        { label: 'OVERRIDE', to: '/Override/' },
        { label: 'NOTIFICATIONS', to: '/Notification/' },
        { label: 'POINT MATRIX', to: '/PointMatrix/Create/' },
        { label: 'QA MANAGER', to: '/Maintenance/' },
        { label: 'DISTRICT GROUP', to: '/DistrictGroup/' },
        { label: 'REJECTION THRESHOLD', to: '/RejectionThreshold/' }, //NEED TO REMOVE
        { label: 'DISPOSITION', to: '/Disposition/DispositionOptions/' },
        { label: 'PRODUCING AREA REGION', to: '/ProducingAreaRegion/' },
        { label: 'SUMMARY STATISTICS ', to: '/SummaryStatistics/' }
    ],
    pointMatrixTabs: {
        Matrix: [
            { label: 'Create', to: '/PointMatrix/Create/' },
            { label: 'Preview', to: '/PointMatrix/Preview/' }
        ]
    },
    ByPassTabs: {
        Bypass: [
            { label: 'Location', to: '/Bypass/Location/' },
            { label: 'Quartile', to: '/Bypass/Quartile/' }
        ]
    },
    DispositionTabs: {
        Disposition: [
            { label: 'Disposition Options', to: '/Disposition/DispositionOptions/' },
            { label: 'Grower Preferences', to: '/Disposition/GrowerPreferences/' }
        ]
    }
};
