import { SUMMARY_LIST, USER_PRODUCING_AREA } from '../Constant/ActionConstant';

const initialState = {
    summaryStatsList: [],
    userProducingArea: []
};

const summaryStatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUMMARY_LIST:
            return { ...state, summaryStatsList: action.payload };
        case USER_PRODUCING_AREA:
            let userProducingArea = action.payload.map((item) => {
                return { value: item.ProducingArea, label: item.ProducingAreaCode };
            });
            return { ...state, userProducingArea: userProducingArea };
        default:
            return state;
    }
};

export default summaryStatsReducer;
