// DrcMaintenancePage.js
// This component manages data correlated to specific data type used in the
// application. It can edit, add, and delete data from the DB through the
// functions passed in.

// Props:
// type: Arg type String. The type represents the Data Type itself that is being
//      maintained by this page. It is used for titles and labels in the component.
// columns(recommended): Arg type Array of Objects. Objects must contain key and name
//      pair correlating to the Data types key and the display name for that key. There
//      is also an optional prop called validationType that denotes the validation on
//      the maintenance input options. Right now number is supported and by default a
//      value is treated as type string.
//      ex: [{key:"CriteriaOptions", name:"Berry Type"}, {key:"CriteriaOrder", name:"Type Id", validationType:"number"}]
// data: Arg type Array of objects. Contains the values currently contained in the
//      application. This is the data that will be displayed in the table and edited
//      in the maintenance page.
//      ex: [{CriteriaOptions: "Straw", CriteriaOrder: "1"}, {CriteriaOptions: "Rasp", CriteriaOrder: "2"}]
// onAdd: Arg type Function. Function that handles adding a new value for the data type.
// onEdit: Arg type Function. Function that handles editing a value for the data type.
// onDelete: Arg type Function. Function that handles deleting a value for the data type.
// readOnly(default=false): Arg type Boolean. If set to true, hides editing features of the
//      maintenance page.
// addDisabled(default=false): Arg type Boolean. This property disables add functions in DrcMaintenance.
// editDisabled(default=false): Arg type Boolean. This property disables edit functions in DrcMaintenance.

import React, { Component } from 'react';
import {
    DrcMain,
    DrcPanel,
    DrcGrid,
    DrcDialog,
    DrcButton,
    DrcInput,
    DrcSwitch,
    DrcSelect,
    DrcKeyValueTable,
    DrcCheckbox,
    DrcDatePicker
} from 'driscolls-react-components';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Data } from 'react-data-grid-addons';
import { DuExcelUtilities, DuThemeUtilities, DuValidationUtilities } from 'driscolls-react-utilities';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import cloneDeep from 'lodash/cloneDeep';

const styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: '100%'
        }
    },
    legendTitle: {
        fontSize: '1.5rem'
    },
    actionButton: {
        minWidth: '40px',
        display: 'block',
        margin: '0px !important',
        padding: '9px 8px 3px'
    },
    actionButtonError: {
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    },
    required: {
        color: DuThemeUtilities.DefaultColors.primary.red
    },
    radio: {
        color: theme.light.accent.primary,
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    }
});

const selectors = Data.Selectors;

const allowedStringValuesRegex = RegExp(/[,?\/#!$%\^&\*;:{}=_`"~()]/);
const allowedNumberValuesRegex = RegExp(/^[0-9]*$/);

export const MAINTENANCE_PAGE_CONSTANTS = {
    ORIENTATION_HORIZONTAL: 0,
    ORIENTATION_VERTICAL: 1,
    ACTION_HIDDEN: 0,
    ACTION_FIRST_FROZEN: 1,
    ACTION_LAST_FROZEN: 2,
    ACTION_FIRST: 3,
    ACTION_LAST: 4
};

const defaultColumnProperties = {
    resizable: true,
    filterable: true
};

const DEFAULT_TEXT = {
    PageTitle: 'Maintenance',
    AddBtn: 'Add',
    ExportBtn: 'Download',
    CancelBtn: 'Cancel',
    SaveBtn: 'Save',
    ConfirmAcceptBtn: 'Accept',
    ConfirmRejectBtn: 'Oops, Just Kidding'
};

const DEFAULT_OPTIONS = {
    EnableDelete: true,
    EnableEdit: true,
    EnableAdd: true,
    EnableExport: true,
    Orientation: MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL,
    ActionColumnSetting: MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST_FROZEN,
    ShowCount: true,
    OverrideAdd: null
};

class DrcPageMaintenance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            dialogEditOpen: false,
            dialogAddNewOpen: false,
            dialogText: '',
            dialogConfirm: () => {
                /* Add Functionality */
            },
            addEditText: 'Add New ' + this.props.type,
            type: '',
            oldValue: {},
            isEdit: false,
            isEnabled: false,
            editedValue: {},
            helperText: [],
            editArray: [],
            readOnlyArray: [],
            errorCount: 0,
            filters: {},
            textOptions: { ...DEFAULT_TEXT },
            settings: { ...DEFAULT_OPTIONS },
            columns: []
        };

        this.onDialogYes = this.onDialogYes.bind(this);
        this.onDialogNo = this.onDialogNo.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.onAddEditYes = this.onAddEditYes.bind(this);
        this.onAddEditNo = this.onAddEditNo.bind(this);
        this.openAddNew = this.openAddNew.bind(this);
        this.export = this.export.bind(this);
        this.onAddNewYes = this.onAddNewYes.bind(this);
        this.onAddNewNo = this.onAddNewNo.bind(this);
        this.buildEditArray = this.buildEditArray.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.allFieldsFilled = this.allFieldsFilled.bind(this);
        this.onSelectValueChange = this.onSelectValueChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        this.onLoad();
    }

    onLoad = () => {
        let stateChange = {};
        let optionsFromProps = {};

        if (typeof this.props.disableDelete === 'boolean') {
            optionsFromProps.EnableDelete = !this.props.readOnly && !this.props.disableDelete;
        }

        if (typeof this.props.disableEdit === 'boolean') {
            optionsFromProps.EnableEdit = !this.props.readOnly && !this.props.disableEdit;
        }

        if (typeof this.props.addBtn === 'boolean') {
            optionsFromProps.EnableAdd = this.props.addBtn;
        }

        if (typeof this.props.exportAllowed === 'boolean') {
            optionsFromProps.EnableExport = this.props.exportAllowed;
        }

        if (typeof this.props.orientation === 'string') {
            optionsFromProps.Orientation = this.props.orientation;
        }

        let settings = { ...DEFAULT_OPTIONS, ...optionsFromProps, ...(this.props.settings || {}) };

        if (!this.isEquivalent(this.state.settings, settings)) {
            stateChange.settings = settings;
        }

        let textOptions = { ...DEFAULT_TEXT, ...(this.props.textOptions || {}) };

        if (!this.isEquivalent(this.state.textOptions, textOptions)) {
            stateChange.textOptions = textOptions;
        }

        if (Object.getOwnPropertyNames(stateChange).length > 0) {
            this.setState(stateChange);
        }
    };

    //TODO: Make this into a generic utility we can use elsewhere
    isEquivalent = (a, b) => {
        // Create arrays of property names
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    };

    handleFilterChange(filter) {
        let filters = this.state.filters;
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    }

    componentDidUpdate() {
        if (this.state.editArray.length < 1) {
            this.buildEditArray();
        }
    }

    async buildEditArray() {
        const that = this;
        let returnArray = [];
        let returnReadOnlyArray = [];
        let columns = this.getColumns();
        let orient = this.state.settings.Orientation;

        let isDisabled = that.props.editDisabled && that.state.isEdit ? true : that.props.addDisabled && !that.state.isEdit ? true : false;

        columns.forEach(function (c, i) {
            let columnDisabled = c.editDisabled && that.state.isEdit ? true : c.addDisabled && !that.state.isEdit ? true : false;

            if (c.isDisabled === true) {
                let displayValue;
                if (!that.state.editedValue[c.key] || that.state.editedValue[c.key] !== c.displayValue) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[c.key] = c.displayValue;
                    that.setState({ editedValue });
                    displayValue = c.displayValue;
                } else {
                    displayValue = that.state.editedValue[c.key];
                }

                if (!c.isHidden) {
                    returnReadOnlyArray.push(
                        <div className="col-sm-6" key={i}>
                            <DrcKeyValueTable>{[{ key: c.name, value: displayValue }]}</DrcKeyValueTable>
                        </div>
                    );
                }
            } else if (c.inputType === 'Select') {
                let optionValue = that.state.editedValue[c.key] || '';
                let option = optionValue ? c.selectOptions.find((opt) => opt.value === optionValue) : null;

                returnArray.push(
                    <DrcSelect
                        label={c.name}
                        name={c.key}
                        options={c.selectOptions}
                        value={option}
                        key={i}
                        disabled={isDisabled || columnDisabled}
                        index={that.props.index}
                        onChange={(options, type) => that.onSelectValueChange(options, type, that.state.name)}
                        InputLabelProps={{ shrink: true }}
                        className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? 'col-xs-12 col-sm-6' : 'col-xs-12'}
                        style={{ marginRight: 0 }}
                        required={c.isRequired}
                        isMulti={!!c.isMulti}
                    />
                );
            } else if (c.inputType === 'Switch') {
                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcSwitch
                            checked={that.state.editedValue[c.key]}
                            value={that.state.editedValue[c.key]}
                            disabled={isDisabled || columnDisabled}
                            onChange={() => that.handleSwitchChange(c.key)}
                            style={{ margin: 'auto', padding: 'auto' }}
                            required={c.isRequired}
                        >
                            {c.name}
                        </DrcSwitch>
                    </div>
                );
            } else if (c.inputType === 'Checkbox') {
                if (!c.options) {
                    returnArray.push(
                        <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                            <FormControlLabel
                                control={
                                    <DrcCheckbox
                                        checked={that.state.editedValue[c.key]}
                                        value={that.state.editedValue[c.key]}
                                        disabled={isDisabled || columnDisabled}
                                        onChange={() => that.handleSwitchChange(c.key)}
                                        style={{ margin: 'auto', padding: 'auto', color: `${c.color}` }}
                                        required={c.isRequired}
                                    ></DrcCheckbox>
                                }
                                label={
                                    <React.Fragment>
                                        {c.name}
                                        {c.isRequired ? <span className={that.props.classes.required}>*</span> : null}
                                    </React.Fragment>
                                }
                            />
                        </div>
                    );
                } else {
                    const checkBoxArray = [];
                    let checked = false;
                    for (let i = 0; i < c.options.length; i++) {
                        if (that.state.editedValue[c.key] && that.state.editedValue[c.key].includes(c.options[i])) {
                            checked = true;
                        } else {
                            checked = false;
                        }
                        checkBoxArray.push(
                            <FormControlLabel
                                name={c.name}
                                value={that.state.editedValue[c.key]}
                                control={
                                    <DrcCheckbox
                                        id={`${c.name}${i}`}
                                        checked={checked}
                                        disabled={isDisabled || columnDisabled}
                                        onChange={(event) => that.handleCheckboxChange(event, c.key, c.options[i])}
                                        style={{ margin: 'auto', padding: 'auto', color: `${c.color}` }}
                                        required={c.isRequired}
                                    ></DrcCheckbox>
                                }
                                label={<React.Fragment>{c.options[i]}</React.Fragment>}
                            />
                        );
                    }

                    returnArray.push(
                        <div
                            style={{ padding: '10px' }}
                            className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-12'}
                            key={i}
                        >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    <React.Fragment>
                                        {c.name}
                                        {c.isRequired ? <span className={that.props.classes.required}>*</span> : null}
                                    </React.Fragment>
                                </FormLabel>
                                <FormGroup classes={that.props.classes.required} style={{ flexDirection: 'row' }}>
                                    {checkBoxArray}
                                </FormGroup>
                            </FormControl>
                        </div>
                    );
                }
            } else if (c.inputType === 'Radio') {
                let checked = true;
                let radioButtons = [];

                for (let i = 0; i < c.options.length; i++) {
                    if (that.state.editedValue[c.key]) {
                        if (!that.state.editedValue[c.key] || that.state.editedValue[c.key] === c.options[i]) {
                            checked = true;
                        } else {
                            checked = false;
                        }
                    } else {
                        if (i !== 0) {
                            checked = false;
                        }
                    }

                    radioButtons.push(
                        <FormControlLabel
                            name={c.name}
                            value={that.state.editedValue[c.key]}
                            control={
                                <Radio
                                    style={{ color: `${c.color}` }}
                                    checked={checked}
                                    disabled={isDisabled || columnDisabled}
                                    onChange={() => that.handleRadioButtonChange(c.key, c.options[i])}
                                    required={c.isRequired}
                                    className={that.props.classes.radio}
                                />
                            }
                            label={c.options[i]}
                        />
                    );
                }

                if (!that.state.editedValue[c.key]) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[c.key] = c.options[0];
                    that.setState({ editedValue });
                }
                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        {radioButtons}
                    </div>
                );
            } else if (c.inputType === 'Date') {
                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcDatePicker
                            clearable
                            label={c.name}
                            name={c.key}
                            disabled={isDisabled || columnDisabled}
                            InputLabelProps={{ shrink: true }}
                            onChange={(val) => that.handleDateChange(c.key, val)}
                            selectedDate={that.state.editedValue[c.key]}
                            required={c.isRequired}
                        />
                    </div>
                );
            } else {
                let type = (c.inputType || 'text').toLowerCase();

                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? 'col-xs-12 col-sm-6' : 'col-xs-12'} key={i}>
                        <DrcInput
                            label={c.name}
                            disabled={isDisabled || columnDisabled}
                            name={c.key}
                            value={that.state.editedValue[c.key] || ''}
                            onChange={(evt) => that.handleAddEditChange(evt, i)}
                            helperText={that.state.helperText[i]}
                            InputLabelProps={{ shrink: true }}
                            style={{ marginRight: 0 }}
                            required={c.isRequired}
                            type={type}
                        />
                    </div>
                );
            }
        });

        if (returnArray.length !== 0 || returnReadOnlyArray.length !== 0) {
            this.setState({
                editArray: returnArray,
                readOnlyArray: returnReadOnlyArray
            });
        }
    }

    onDialogYes() {
        if (this.state.isEdit) {
            this.state.dialogConfirm(this.state.oldValue, this.state.editedValue);
        } else {
            this.state.dialogConfirm(this.state.editedValue);
        }

        this.setState(
            {
                dialogOpen: false,
                dialogConfirm: () => {},
                addEditText: 'Add New ' + this.props.type,
                type: '',
                oldValue: {},
                editedValue: {},
                isEnabled: false,
                isEdit: false,
                helperText: []
            },
            this.buildEditArray
        );
    }

    onDialogNo() {
        this.setState(
            {
                dialogOpen: false,
                dialogConfirm: () => {},
                addEditText: 'Add New ' + this.props.type,
                type: '',
                oldValue: {},
                editedValue: {},
                isEnabled: false,
                isEdit: false,
                helperText: []
            },
            this.buildEditArray
        );
    }

    handleSwitchChange(name) {
        let retEditArray = this.state.editedValue;
        if (this.state.editedValue[name]) {
            retEditArray[name] = false;
        } else {
            retEditArray[name] = true;
        }
        let submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    }

    handleCheckboxChange(event, name, berry) {
        let retEditArray = this.state.editedValue;
        if (!event.target.checked) {
            retEditArray[name] = retEditArray[name].filter((ret) => ret !== berry);
        } else {
            if (!retEditArray[name]) {
                retEditArray[name] = [];
            }
            retEditArray[name].push(berry);
            retEditArray[name].sort();
        }
        let helperText = this.state.helperText;
        let newErrorCount = helperText.filter((ht) => ht !== '').length;
        let submitEnabled = this.allFieldsFilled() && newErrorCount <= 0;
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    }

    handleRadioButtonChange = (name, type) => {
        let retEditArray = this.state.editedValue;
        retEditArray[name] = type;

        let submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    };

    handleDateChange = (name, value) => {
        let retEditArray = { ...this.state.editedValue };
        retEditArray[name] = value;

        let submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    };

    handleAddEditChange = (event, i) => {
        const name = event.target.name;
        if (this.state.editedValue[name] !== event.target.value) {
            let helperText = this.state.helperText;
            let retEdit = this.state.editedValue;
            let validationType = this.props.columns[i].validationType;

            if (!validationType) {
                if (this.props.columns[i].isRequired) {
                    validationType = 'required';
                }
            }

            let helpText = '';
            switch (this.props.columns[i].validationType || this.props.columns[i].inputType) {
                case 'number':
                    if (!allowedNumberValuesRegex.test(event.target.value) || isNaN(Number(event.target.value))) {
                        helpText = 'Please Remove Illegal Characters';
                    } else {
                        if (!isNaN(this.props.columns[i].minValue) && Number(event.target.value) < this.props.columns[i].minValue) {
                            helpText = 'Please Use a Larger Value';
                        } else if (!isNaN(this.props.columns[i].maxValue) && Number(event.target.value) > this.props.columns[i].maxValue) {
                            helpText = 'Please Use a Smaller Value';
                        } else {
                            helpText = this.validateTextLength(event, i);
                        }
                    }
                    break;
                case 'required':
                    if (event.target.value === '') {
                        helpText = 'This field is required';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                case 'regex':
                    if (!this.props.columns[i].regex.test(event.target.value)) {
                        helpText = this.props.columns[i].regexDescription || 'Please Correct Input';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                case 'email':
                    if (!DuValidationUtilities.EMAIL_REGEXP.test(event.target.value)) {
                        helpText = this.props.columns[i].regexDescription || 'Please Add a Valid Email';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                default:
                    if (allowedStringValuesRegex.test(event.target.value)) {
                        helpText = 'Please Remove Illegal Characters';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
            }

            helperText[i] = helpText;

            if (this.props.columns[i].validationType === 'number' || this.props.columns[i].inputType === 'number') {
                retEdit[name] = parseInt(event.target.value);
            } else {
                retEdit[name] = event.target.value;
            }

            let newErrorCount = helperText.filter((ht) => ht !== '').length;
            let submitEnabled = this.allFieldsFilled() && newErrorCount <= 0;
            this.setState({ editedValue: retEdit, helperText: helperText, errorCount: newErrorCount, isEnabled: submitEnabled }, this.buildEditArray);
        }
    };

    validateTextLength = (event, i) => {
        if (!isNaN(this.props.columns[i].minLength) && (event.target.value || '').length < this.props.columns[i].minLength) {
            return 'Required Length Too Small';
        } else if (!isNaN(this.props.columns[i].maxLength) && (event.target.value || '').length > this.props.columns[i].maxLength) {
            return 'Required Length Too Large';
        }

        return '';
    };

    onSelectValueChange(option, nameObj) {
        const name = nameObj.name;
        if (!this.state.editedValue[name] || this.state.editedValue[name].value !== option.value) {
            let retEdit = this.state.editedValue;
            //todo: allow empty values or null values
            //see which one would make more sense
            retEdit[name] = option.value;
            let submitEnabled = this.allFieldsFilled();
            this.setState({ editedValue: retEdit, isEnabled: submitEnabled }, this.buildEditArray);
        }
    }

    allFieldsFilled = () => {
        let saveAllowed = true;
        let editSaveAllowed = false;
        let MandatoryCheckPassed = true;
        const that = this;

        this.props.columns.forEach((value) => {
            if (Array.isArray(that.state.editedValue[value.key])) {
                if (value.isRequired && !that.state.editedValue[value.key].length) {
                    saveAllowed = false;
                    MandatoryCheckPassed = false;
                }
                if (that.state.isEdit) {
                    if (JSON.stringify(that.state.oldValue[value.key]) === JSON.stringify(that.state.editedValue[value.key])) {
                        saveAllowed = false;
                    } else {
                        editSaveAllowed = true;
                    }
                }
            } else {
                if ((that.state.editedValue[value.key] === '' || that.state.editedValue[value.key] === undefined) && value.isRequired) {
                    saveAllowed = false;
                }
                if (that.state.isEdit) {
                    if (that.state.editedValue[value.key] === that.state.oldValue[value.key]) {
                        saveAllowed = false;
                    } else {
                        editSaveAllowed = true;
                    }
                }
            }
        });

        return (editSaveAllowed || saveAllowed) && MandatoryCheckPassed;
    };

    onAddEditYes() {
        this.setState({
            dialogEditOpen: false
        });
        let dialogText = '';
        if (this.state.helperText > 0) {
            return;
        } else if (this.state.isEdit) {
            if (typeof this.state.editedValue === 'object') {
                dialogText = [
                    <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to change {this.props.type}? </div>,
                    <div className="row">
                        <DrcGrid className="col-sm-12" rows={[this.state.oldValue]} columns={this.props.columns} minHeight={90} hideCount={true} />
                        <ArrowDownward className="col-sm-12" style={{ fontSize: 70 }} />
                        <DrcGrid className="col-sm-12" rows={[this.state.editedValue]} columns={this.props.columns} minHeight={90} hideCount={true} />
                    </div>
                ];
            } else {
                dialogText =
                    'Are you sure you want to change ' +
                    this.props.type +
                    ' type: "' +
                    this.state.oldValue +
                    '" to "' +
                    this.state.editedValue +
                    '"?';
            }
        } else {
            if (typeof this.state.editedValue === 'object') {
                dialogText = [
                    <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to add new {this.props.type}? </div>,
                    <DrcGrid
                        rows={this.props.gridDataFormatter ? this.props.gridDataFormatter([this.state.editedValue]) : [this.state.editedValue]}
                        columns={this.props.columns}
                        minHeight={90}
                        hideCount={true}
                    />
                ];
            } else {
                dialogText = 'Are you sure you want to add ' + this.props.type + ' type: "' + this.state.editedValue + '"?';
            }
        }

        this.setState({
            dialogOpen: true,
            dialogText: dialogText,
            dialogConfirm: this.state.isEdit ? this.props.onEdit : this.props.onAdd,
            type: this.props.type,
            helperText: []
        });
    }

    onAddEditNo() {
        this.setState(
            {
                dialogEditOpen: false,
                dialogConfirm: () => {
                    /* Add Functionality */
                },
                addEditText: 'Add New ' + this.props.type,
                type: '',
                oldValue: {},
                isEnabled: false,
                isEdit: false,
                editedValue: {},
                helperText: []
            },
            () => this.buildEditArray()
        );
    }

    getCellActions(column, row) {
        let actions = [];

        if (this.state.settings.EnableDelete) {
            actions.push({
                icon: (
                    <DrcButton size="small" className={`${this.props.classes.actionButton} ${this.props.classes.actionButtonError}`}>
                        <DeleteForever />
                    </DrcButton>
                ),
                callback: () => {
                    let editedValue, oldValue;
                    if (row.BerryType && typeof row.BerryType === 'string') {
                        oldValue = { ...row };
                        oldValue.BerryType = [];
                        editedValue = { ...row };
                        editedValue.BerryType = [];
                        let berryType = row.BerryType.split(',');
                        for (let i = 0; i < berryType.length; i++) {
                            oldValue.BerryType.push(berryType[i]);
                            editedValue.BerryType.push(berryType[i]);
                        }
                        this.setState({
                            dialogOpen: true,
                            dialogText: [
                                <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to delete {this.props.type}?</div>,
                                <DrcGrid rows={[row]} columns={this.props.columns} minHeight={90} hideCount={true} />
                            ],
                            dialogConfirm: this.props.onDelete,
                            addEditText: 'Add New ' + this.props.type,
                            type: this.props.type,
                            oldValue: { ...oldValue },
                            editedValue: { ...editedValue },
                            isEdit: false,
                            helperText: []
                        });
                    } else {
                        this.setState({
                            dialogOpen: true,
                            dialogText: [
                                <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to delete {this.props.type}?</div>,
                                <DrcGrid rows={[row]} columns={this.props.columns} minHeight={90} hideCount={true} />
                            ],
                            dialogConfirm: this.props.onDelete,
                            addEditText: 'Add New ' + this.props.type,
                            type: this.props.type,
                            oldValue: cloneDeep(row),
                            editedValue: cloneDeep(row),
                            isEdit: false,
                            helperText: []
                        });
                    }
                }
            });
        }

        if (this.state.settings.EnableEdit) {
            actions.push({
                icon: (
                    <DrcButton size="small" className={this.props.classes.actionButton}>
                        <EditIcon />
                    </DrcButton>
                ),
                callback: () => {
                    let editedValue, oldValue;
                    if (row.BerryType && typeof row.BerryType === 'string') {
                        oldValue = { ...row };
                        oldValue.BerryType = [];
                        editedValue = { ...row };
                        editedValue.BerryType = [];
                        let berryType = row.BerryType.split(',');
                        for (let i = 0; i < berryType.length; i++) {
                            oldValue.BerryType.push(berryType[i]);
                            editedValue.BerryType.push(berryType[i]);
                        }
                        this.setState(
                            {
                                dialogEditOpen: true,
                                addEditText: 'Edit Type: ' + this.props.type,
                                type: this.props.type,
                                oldValue: { ...oldValue },
                                editedValue: { ...editedValue },
                                isEnabled: false,
                                isEdit: true,
                                helperText: []
                            },
                            () => this.buildEditArray()
                        );
                    } else {
                        this.setState(
                            {
                                dialogEditOpen: true,
                                addEditText: 'Edit Type: ' + this.props.type,
                                type: this.props.type,
                                oldValue: cloneDeep(row),
                                editedValue: cloneDeep(row),
                                isEnabled: false,
                                isEdit: true,
                                helperText: []
                            },
                            () => this.buildEditArray()
                        );
                    }
                }
            });
        }

        return { actions: actions }[column.key];
    }

    openAddNew() {
        this.setState(
            {
                dialogAddNewOpen: true
            },
            () => this.buildEditArray()
        );
    }

    export() {
        DuExcelUtilities.Write(this.props.type + 'Maintenance.xlsx', this.getColumns(), this.getRows(this.props.data, this.state.filters));
    }

    onAddNewYes() {
        this.setState({
            dialogAddNewOpen: false
        });

        let dialogText = '';

        if (this.state.errorCount > 0) {
            return;
        } else {
            if (typeof this.state.editedValue === 'object') {
                let editedValue = { ...this.state.editedValue };
                editedValue['NotifyLocation'] = this.props.columns[3].displayValue;
                dialogText = [
                    <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to add new {this.props.type}? </div>,
                    <DrcGrid
                        rows={this.props.gridDataFormatter ? this.props.gridDataFormatter([editedValue]) : [editedValue]}
                        columns={this.props.columns}
                        minHeight={90}
                        hideCount={true}
                    />
                ];
            } else {
                dialogText = 'Are you sure you want to add new' + this.props.type + this.state.editedValue + '"?';
            }
        }

        this.setState({
            dialogOpen: true,
            dialogText: dialogText,
            dialogConfirm: this.state.isEdit ? this.props.onEdit : this.props.onAdd,
            type: this.props.type,
            helperText: []
        });
    }

    onAddNewNo() {
        this.setState(
            {
                dialogAddNewOpen: false,
                dialogConfirm: () => {
                    /* Add Functionality */
                },
                addEditText: 'Add New ' + this.props.type,
                type: '',
                oldValue: {},
                isEnabled: false,
                isEdit: false,
                editedValue: {},
                helperText: []
            },
            () => this.buildEditArray()
        );
    }

    getColumns() {
        let returnValue = [];
        if (this.props.columns === undefined) {
            Object.keys(this.props.data[0] || []).forEach(function (c) {
                returnValue.push({
                    key: c,
                    name: c,
                    ...defaultColumnProperties
                });
            });
        } else {
            this.props.columns.forEach(function (c) {
                returnValue.push(c);
            });
        }
        return returnValue;
    }

    getRows(rows, filters) {
        return selectors.getRows({ rows, filters });
    }

    render() {
        const { data, type, children, classes, fullWidth, className } = this.props;
        const { filters, textOptions, settings } = this.state;
        const readOnly = !settings.EnableAdd && settings.EnableDelete && !settings.EnableEdit;

        const filteredRows = this.getRows(data, filters);
        let columns = this.getColumns();
        columns = columns.map((c) => ({ ...c, ...defaultColumnProperties }));

        if (!readOnly && settings.ActionColumnSetting !== MAINTENANCE_PAGE_CONSTANTS.ACTION_HIDDEN) {
            let actionWidth = !settings.EnableDelete || !settings.EnableEdit ? 58 : 90;

            if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_FIRST_FROZEN) {
                columns = [{ key: 'actions', name: 'Actions', width: actionWidth, frozen: true }, ...columns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST_FROZEN) {
                let frozenColumns = columns.filter((c) => !!c.frozen) || [];
                let normalColumns = columns.filter((c) => !c.frozen) || [];

                columns = [...frozenColumns, { key: 'actions', name: 'Actions', width: actionWidth, frozen: true }, ...normalColumns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_FIRST) {
                let frozenColumns = columns.filter((c) => !!c.frozen) || [];
                let normalColumns = columns.filter((c) => !c.frozen) || [];

                columns = [...frozenColumns, { key: 'actions', name: 'Actions', width: actionWidth }, ...normalColumns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST) {
                columns = [...columns, { key: 'actions', name: 'Actions', width: actionWidth }];
            }
        }

        return (
            <React.Fragment>
                <DrcMain maxWidth={fullWidth ? '100%' : null} transparent className={`${classes.main} ${className}`}>
                    <DrcPanel maxWidth={'100%'}>
                        <div className="row">
                            <div className={settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? 'col-xs-8' : 'col-xs-12'}>
                                <h1 style={{ marginTop: 0 }}>
                                    {type} {textOptions.PageTitle}
                                </h1>
                            </div>
                            {settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? (
                                <div className="col-xs-4">
                                    {settings.EnableAdd ? (
                                        <DrcButton
                                            style={{ marginTop: 0 }}
                                            isPrimary
                                            onClick={settings.OverrideAdd ? settings.OverrideAdd : this.openAddNew}
                                            floatRight
                                        >
                                            {textOptions.AddBtn}
                                        </DrcButton>
                                    ) : null}
                                    {settings.EnableExport ? (
                                        <DrcButton style={{ marginTop: 0 }} isSecondary onClick={this.export} floatRight>
                                            {textOptions.ExportBtn}
                                        </DrcButton>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                        <div className="row">
                            <DrcGrid
                                fullHeightOffset={this.props.fullHeightOffset}
                                className={
                                    classes.grid +
                                    ' ' +
                                    (readOnly
                                        ? 'col-xs-12'
                                        : settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL
                                        ? 'col-xs-12'
                                        : 'col-xs-12  col-sm-8')
                                }
                                rows={filteredRows}
                                columns={columns}
                                getCellActions={this.getCellActions}
                                toolbar={<Toolbar enableFilter={true} />}
                                onAddFilter={(filter) => this.setState({ filters: this.handleFilterChange(filter, this.state.filters) })}
                                onClearFilters={() => this.setState({ filters: {} })}
                                hideCount={!settings.ShowCount}
                            />
                            {!readOnly ? (
                                settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? (
                                    <DrcDialog
                                        maxWidth={'100%'}
                                        open={this.state.dialogEditOpen}
                                        buttons={
                                            <React.Fragment>
                                                <DrcButton isSecondary onClick={this.onAddEditNo} floatRight>
                                                    Cancel
                                                </DrcButton>
                                                <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddEditYes} floatRight>
                                                    Save
                                                </DrcButton>
                                            </React.Fragment>
                                        }
                                        title={this.state.addEditText}
                                    >
                                        <hr />
                                        {this.state.readOnlyArray.length === 0 ? null : (
                                            <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                                                <div className="row">{this.state.readOnlyArray}</div>
                                            </DrcPanel>
                                        )}
                                        <div className="row" style={{ alignItems: 'center' }}>
                                            {this.state.editArray}
                                        </div>
                                    </DrcDialog>
                                ) : (
                                    <div className="col-xs-12 col-sm-4">
                                        <legend className={classes.legendTitle}>{this.state.addEditText}</legend>
                                        <hr />
                                        {this.state.readOnlyArray.length === 0 ? null : (
                                            <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                                                <div className="row">{this.state.readOnlyArray}</div>
                                            </DrcPanel>
                                        )}
                                        {this.state.editArray}
                                        <DrcButton isSecondary onClick={this.onAddEditNo} floatRight>
                                            {textOptions.CancelBtn}
                                        </DrcButton>
                                        <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddEditYes} floatRight>
                                            {textOptions.SaveBtn}
                                        </DrcButton>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </DrcPanel>
                </DrcMain>
                <DrcDialog
                    open={this.state.dialogAddNewOpen}
                    title={this.state.addEditText}
                    buttons={
                        <React.Fragment>
                            <DrcButton isSecondary onClick={this.onAddNewNo} floatRight>
                                {textOptions.CancelBtn}
                            </DrcButton>
                            <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddNewYes} floatRight>
                                {textOptions.SaveBtn}
                            </DrcButton>
                        </React.Fragment>
                    }
                >
                    <hr />
                    {this.state.readOnlyArray.length === 0 ? null : (
                        <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                            <div className="row">{this.state.readOnlyArray}</div>
                        </DrcPanel>
                    )}
                    <div className="row">{this.state.editArray}</div>
                </DrcDialog>
                <DrcDialog
                    className={classes.dialog}
                    title={this.state.dialogText}
                    open={this.state.dialogOpen}
                    buttons={
                        <React.Fragment>
                            <DrcButton isPrimary onClick={this.onDialogYes}>
                                {textOptions.ConfirmAcceptBtn}
                            </DrcButton>
                            <DrcButton isSecondary onClick={this.onDialogNo}>
                                {textOptions.ConfirmRejectBtn}
                            </DrcButton>
                        </React.Fragment>
                    }
                />
                {children}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DrcPageMaintenance);
