import React, { useEffect } from 'react';
import AnalystTabRouting from '../../components/AnalystTabRouting';
import TabRouting from '../../components/TabRouting';
import { connect } from 'react-redux';
import { selectDisplayTabs } from '../../actions/MasterActions';
import { AnalystTabs } from '../../Constant/Tabs';

const selectedSubTabIndex = 0;
const PointMatrixCreate = (props) => {
    useEffect(() => {
        if (!Object.keys(props.tabs).length) {
            props.selectDisplayTabs(AnalystTabs);
        }
    }, []);

    const selectRouting = () => {
        let tabsLength = Object.keys(props.tabs).length;
        if (tabsLength) {
            if (!props.tabs['ByPassTabs'] && !props.tabs['DispositionTabs']) {
                return <AnalystTabRouting selectedMainTabIndex={0} selectedSubTabIndex={selectedSubTabIndex}></AnalystTabRouting>;
            } 
            else {
                return <TabRouting selectedMainTabIndex={3} selectedSubTabIndex={selectedSubTabIndex}></TabRouting>;
            }
        } else {
            return '';
        }
    };
    return <>{selectRouting()}</>;
};

const mapStateToProps = (state) => {
    return {
        tabs: state.masterReducer.tabs
    };
};

export default connect(mapStateToProps, { selectDisplayTabs })(PointMatrixCreate);
