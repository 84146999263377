import { DISPOSITION_DETAILS, SAVED_DISPOSITION_DETAILS, FACTOR_TYPE } from '../Constant/ActionConstant';

export const setDispositionData = (data) => ({
    type: DISPOSITION_DETAILS,
    payload: data
});

export const getSavedDispositionData = (data) => ({
    type: SAVED_DISPOSITION_DETAILS,
    payload: data
});
export const setFactorType = (data) => ({
    type: FACTOR_TYPE,
    payload: data
});
