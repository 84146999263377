import { combineReducers } from 'redux';
import masterReducer from './MasterReducer';
import notificationReducer from './NotificationReducer';
import overrideReducer from './OverrideReducer';
import quartileReducer from './QuartileReducer';
import pointMatrixReducer from './PointMatrixReducer';
import managerMaintenanceReducer from './ManagerMaintenanceReducer';
import dispositionReducer from './DispositionReducer';
import { localizeReducer } from 'react-localize-redux';
import { SHOW_LOADING_SCREEN, HIDE_LOADING_SCREEN, SET_ERRORS, HIDE_ERROR_DIALOG, SET_PAGE_TITLE, SET_PAGE_DIRTY } from '../actions/actions';
import RejectionThresholdReducer from './RejectionThresholdReducer';
import growerPreferencesReducer from './GrowerPreferencesReducer';
import ProducingAreaRegionReducer from './ProducingAreaRegioReducer';
import summaryStatsReducer from './SummaryStatsReducer';

const initialState = {
    showLoadingScreen: false,
    loadingMessage: 'Loading...',
    pageTitle: '',
    errorDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Whazzzuuppp'
    },
    isPageDirty: false
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: action.payload
            });
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingMessage: action.payload.title
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false
            };
        case SET_ERRORS:
            return {
                ...state,
                errorDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                errorDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SET_PAGE_DIRTY:
            return Object.assign({}, state, {
                ...state,
                isPageDirty: action.payload
            });
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    localize: localizeReducer,
    masterReducer,
    notificationReducer,
    overrideReducer,
    quartileReducer,
    pointMatrixReducer,
    managerMaintenanceReducer,
    dispositionReducer,
    RejectionThresholdReducer,
    growerPreferencesReducer,
    ProducingAreaRegionReducer,
    summaryStatsReducer
});
