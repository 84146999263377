import overrideMappingTypesGroup from './Override';
import RejectionThresholdMappingTypesGroup from './RejectionThreshold';
import GrowerPreferenceseMappingTypesGroup from './GrowerPreferences';
import ranchDetailsMappingTypesGroup from './RanchDetails';
import masterDataMappingTypesGroup from './masterData';
import locationBypassMappingTypesGroup from './Location';
import quartileMappingTypesGroup from './Quartile';
import quartileByPassMappingTypesGroup from './QuartileByPass';
import notificationMappingTypesGroup from './Notification';
import pointMatrixTypesGroup from './PointMatrix';
import { Middleware } from 'one-ring';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';

function ConfigureAllMiddleware() {
    overrideMappingTypesGroup.ConfigureMiddleWare();
    RejectionThresholdMappingTypesGroup.ConfigureMiddleWare();
    GrowerPreferenceseMappingTypesGroup.ConfigureMiddleWare();
    ranchDetailsMappingTypesGroup.ConfigureMiddleWare();
    masterDataMappingTypesGroup.ConfigureMiddleware();
    locationBypassMappingTypesGroup.ConfigureMiddleWare();
    notificationMappingTypesGroup.ConfigureMiddleWare();
    quartileMappingTypesGroup.ConfigureMiddleWare();
    quartileByPassMappingTypesGroup.ConfigureMiddleWare();
    pointMatrixTypesGroup.ConfigureMiddleWare();
    Middleware.RegisterDefaultPayloadHandler((token) => {
        const email = DuAuthenticationUtilities.GetEmail(token);

        return {
            LastUpdatedBy: email || 'Bad Token',
            LastUpdatedDateTime: new Date().toISOString()
        };
    });
}

const AllMiddleware = {
    ConfigureAllMiddleware
};

export default AllMiddleware;
