import React from 'react';
import { DrcSelect, DrcIcons } from 'driscolls-react-components';
import { components } from 'react-select';
import { BERRY_TYPE, BERRY_TYPE_PLACEHOLDER } from '../language/english';
import DrcMultiSelect from './DrcMultiSelect';

const Option = (props) => {
    return (
        <components.Option {...props}>
            {DrcIcons.GetSmallBerryIcon(props.data.value)}
            <span style={{ marginLeft: '10px', lineHeight: '22px', verticalAlign: 'top' }}>{props.data.label}</span>
        </components.Option>
    );
};

const ValueContainer = ({ children, ...props }) => {
    if (!props.hasValue) {
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }
    return (
        <components.ValueContainer {...props}>
            {!props.isMulti ? (
                <>
                    {DrcIcons.GetSmallBerryIcon(props.selectProps.value.value)}
                    <span style={{ marginLeft: '10px', lineHeight: '22px', verticalAlign: 'top' }}>{props.selectProps.value.label}</span>
                </>
            ) : (
                <>
                    {props.selectProps.value.map((berry) => (
                        <>
                            {DrcIcons.GetSmallBerryIcon(berry.value)}
                            <span style={{ marginLeft: '10px', lineHeight: '22px', verticalAlign: 'top' }}>{berry.label}</span>
                        </>
                    ))}
                </>
            )}
        </components.ValueContainer>
    );
};

const BerryTypeSelect = (props) => {
    const { value, onChange, options, isMulti, ...other } = props;
    return (
        <>
            {!isMulti ? (
                <DrcSelect
                    name="BerryType"
                    label={BERRY_TYPE}
                    value={value}
                    onChange={onChange}
                    options={options}
                    components={{ Option, ValueContainer }}
                    placeholder={BERRY_TYPE_PLACEHOLDER}
                    {...other}
                />
            ) : (
                <DrcMultiSelect
                    name="BerryType"
                    options={options}
                    label={BERRY_TYPE}
                    placeholder={BERRY_TYPE_PLACEHOLDER}
                    isMulti={true}
                    onChange={onChange}
                    components={{ Option, ValueContainer }}
                    {...other}
                ></DrcMultiSelect>
            )}
        </>
    );
};

export default BerryTypeSelect;
