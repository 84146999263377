import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { withAuth } from '@okta/okta-react';
import DrcPageRejectionThreshold from '../../components/RejectionThreshold/RejectionThreshold';
import { DrcPanel, DrcSelect } from 'driscolls-react-components';
import DrcMultiSelect from '../../components/DrcMultiSelect';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setGrowerData, setFactorTypeData, setGrowerPreferencesData, setRanchData } from '../../actions/GrowerPreferencesAction';
import {
    PRODUCING_AREA,
    PRODUCING_AREA_PLACEHOLDER,
    BERRY_TYPE,
    BERRY_TYPE_PLACEHOLDER,
    GROWER_NAME,
    RANCH_NAME,
    FACTOR_TYPE,
    FACTOR_TYPE_PLACEHOLDER,
    RANCH_NUMBER_PLACEHOLDER,
    GROWER_NAME_PLACEHOLDER
} from '../../language/english';
import APIEndPoints from '../../services/api';
import { DuDateUtilities, DuAuthenticationUtilities } from 'driscolls-react-utilities';

const PAGE_TITLE = 'Grower Preferences';
const PAGE_TYPE = 'GrowerPreferences';
let styles = (theme) => ({
    main: {
        margin: 0,
        paddingBottom: '0px !important'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    },
    success: {
        color: theme.palette.primary.main
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    layout: {
        width: '97%'
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px',
            width: '100%'
        },
        '& .p-datatable-thead': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            color: '#fff'
        },
        '& .p-datatable-row': {
            fontWeight: 600
        },
        '& .p-datatable-scrollable-wrapper': {
            maxHeight: 600
        }
    }
});

const GrowerPreferences = (props) => {
    const getColumn = (value, row) => {
        for (let key in row) {
            if (row[key] === value) {
                return key;
            }
        }
    };
    const cellFormatter = ({ value, row }) => {
        let column = getColumn(value, row);
        // if (column === 'NotifyEmailAddress' || column === 'ModifiedBy' || column === 'CreatedBy') {
        //     return (
        //         <span>
        //             <a style={{ fontWeight: '500', fontSize: '13px', color: '#6f5091', textDecoration: 'none' }} href={'mailto:' + value} title={value}>
        //                 {value}
        //             </a>
        //         </span>
        //     );
        // } else
        if (column === 'created_at' || column === 'CreatedDateTime') {
            return <span title={DuDateUtilities.FormatDateTimeFromIso(value)}>{DuDateUtilities.FormatDateTimeFromIso(value)}</span>;
        }
    };
    const [BerryType, setBerryType] = useState(null);
    const [producingAreaFilter, setProducingAreaFilter] = useState('');
    const [ranchFilter, setRanchFilter] = useState('');
    const [factorType, setFactorType] = useState('');
    const [factorTypeFilter, setFactorTypeFilter] = useState(null);
    const [growerFilter, setGrowerFilter] = useState('');
    const [grower, setGrower] = useState([]);
    const [producingAreaByUser, setProducingAreaByUser] = useState([]);

    const columns = [
        {
            key: 'company_number',
            name: 'GROWER Number',
            filter: false,
            columnTemplate: (row) => row.company_number
        },
        {
            key: 'company_name',
            name: 'GROWER NAME',
            filter: false,
            columnTemplate: (row) => row.company_name
        },
        {
            key: 'producingarea_name',
            name: 'PRODUCING AREA',
            filter: false,
            columnTemplate: (row) => row.producingarea_name
        },
        {
            key: 'ranch_number',
            name: 'RANCH',
            filter: false,
            columnTemplate: (row) => row.ranch_number
        },
        {
            key: 'ranch_name',
            name: 'RANCH NAME',
            filter: false,
            columnTemplate: (row) => row.ranch_name
        },

        {
            key: 'BerryType',
            name: 'BERRY TYPE',
            filter: false,
            columnTemplate: (row) => row.BerryType
        },
        {
            key: 'FactorType',
            name: 'FACTOR',
            filter: false,
            columnTemplate: (row) => row.FactorType
        },
        {
            key: 'Priority 1',
            name: 'PRIORITY 1',
            filter: false,
            columnTemplate: (row) => row['Priority 1']
        },
        {
            key: 'Priority 2',
            name: 'PRIORITY 2',
            filter: false,
            columnTemplate: (row) => row['Priority 2']
        },
        {
            key: 'Priority 3',
            name: 'PRIORITY 3',
            filter: false,
            columnTemplate: (row) => row['Priority 3']
        },
        {
            key: 'created_at',
            name: 'CREATED AT',
            width: 150,
            resizable: true,
            filter: false,
            formatter: cellFormatter,
            columnTemplate: (row) => row.created_at
        }
        // {
        //     key: 'updated_by',
        //     name: 'MODIFIED BY',
        //     filter: false,
        //     columnTemplate: (row) => row.updated_by
        // },
    ];

    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: '',
        severity: 'success'
    });
    const [formattedData, setFormattedData] = useState([]);
    const [ranch, setRanch] = useState([]);
    const [isGrowerClear, setisGrowerClear] = useState([]);
    const [isRanchClear, setisRanchClear] = useState([]);

    const { classes } = props;

    useEffect(() => {
        getProducingArea((data) => {
            if (data.length !== 0) {
                let procuingArea = data.map((item) => {
                    return { value: item.ProducingArea, label: item.ProducingAreaCode };
                });
                setProducingAreaByUser(
                    procuingArea.sort(function (a, b) {
                        return Number(a.value - b.value);
                    })
                );
            } else {
                setProducingAreaByUser([]);
            }
        });

        getFactorTypeData((data) => {
            if (data.length !== 0) {
                let FactorType = data.map((item) => {
                    return { value: item.LookupCode, label: item.LookupCode };
                });
                setFactorType(FactorType);
            } else {
                setFactorType([]);
            }
        });

        getData(null, null, null, null, null, (data) => {
            setFormattedData(
                data.sort(function (a, b) {
                    const date1 = new Date(a.created_at);
                    const date2 = new Date(b.created_at);

                    return date2 - date1;
                })
            );
        });
    }, []);

    useEffect(() => {
        if (producingAreaFilter !== '') {
            getGrowers(producingAreaFilter, (data) => {
                if (data.length !== 0) {
                    let Grower = data.map((item) => {
                        return { value: item.GrowerNbr, label: item.GrowerNbr + '-' + item.GrowerName };
                    });
                    setGrower(Grower);
                } else {
                    setGrower([]);
                }
            });
        } else {
            props.setGrowerData([]);
            setGrower([]);
            props.setRanchData([]);
            setRanch([]);
            setisGrowerClear([]);
            setisRanchClear([]);
        }
    }, [producingAreaFilter]);

    useEffect(() => {
        if (growerFilter !== '') {
            getRanches(growerFilter, (data) => {
                if (data.length !== 0) {
                    let Grower = data.map((item) => {
                        return { value: item.RanchNbr, label: item.RanchNbr + '-' + item.RanchName };
                    });
                    setRanch(Grower);
                } else {
                    setRanch([]);
                }
            });
        } else {
            props.setRanchData([]);
            setRanch([]);
            setisRanchClear([]);
        }
    }, [growerFilter]);

    useEffect(() => {
        getData(producingAreaFilter, ranchFilter, factorTypeFilter, BerryType ? BerryType.label : '', growerFilter, (data) => {
            if (data) {
                setFormattedData(
                    data.sort(function (a, b) {
                        const date1 = new Date(a.created_at);
                        const date2 = new Date(b.created_at);

                        return date2 - date1;
                    })
                );
            } else {
                setFormattedData([]);
            }
        });
    }, [BerryType, producingAreaFilter, growerFilter, factorTypeFilter, ranchFilter]);

    const getProducingArea = (callback) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'managerMaintenance',
                token,
                APIEndPoints.GET_USER_PRODUCING_AREAS(props.loggedInUser),
                'GET',
                { Type: 'managerMaintenance' },
                { loadingMessage: '' }
            )
                .then((data) => {
                    if (data && data.length) {
                        callback(data);
                    } else {
                        callback([]);
                    }
                })
                .catch(() => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';
                    callback([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const getGrowers = (producingareacode, callback) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('Grower', token, APIEndPoints.GET_GROWER_BY_PRODUCING_AREA(producingareacode), 'GET', {}, { loadingMessage: '' })
                .then((data) => {
                    if (data && data.Data) {
                        props.setGrowerData(data.Data);
                        callback(data.Data);
                    } else {
                        props.setGrowerData([]);
                        callback([]);
                    }
                })
                .catch(() => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';
                    props.setGrowerData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    const getRanches = (growercode, callback) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('Grower', token, APIEndPoints.GET_RACHES_BY_GROWER(growercode), 'GET', {}, { loadingMessage: '' })
                .then((data) => {
                    if (data && data.Data) {
                        props.setGrowerData(data.Data);
                        callback(data.Data);
                    } else {
                        props.setGrowerData([]);
                        callback([]);
                    }
                })
                .catch(() => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';
                    props.setGrowerData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleBerryTypeChange = (berryType) => {
        setBerryType(berryType);
    };

    const handleOnChangeOfProducingArea = (option) => {
        let prodArea = '';
        let selectedProducingAreas = '';
        if (option || option !== null) {
            option.map((item) => {
                return (selectedProducingAreas += item.value + ',');
            });
            prodArea = selectedProducingAreas.substring(0, selectedProducingAreas.length - 1);
            setProducingAreaFilter(prodArea);
            setisRanchClear([]);
            setisGrowerClear([]);
            setGrowerFilter('');
            setRanchFilter('');
        } else {
            setProducingAreaFilter('');
            setisRanchClear([]);
            setisGrowerClear([]);
            setGrowerFilter('');
            setRanchFilter('');
        }
    };

    const handleOnChangeOfGrower = (option) => {
        let prodArea = '';
        let selectedGowers = '';
        if (option || option !== null) {
            option.map((item) => {
                return (selectedGowers += item.value + ',');
            });
            prodArea = selectedGowers.substring(0, selectedGowers.length - 1);
            setGrowerFilter(prodArea);
            setisGrowerClear(option);
            setisRanchClear([]);
            setRanchFilter('');
        } else {
            setGrowerFilter('');
            setisGrowerClear([]);
            setisRanchClear([]);
        }
    };
    const handleOnChangeOfRanch = (option) => {
        let ranch = '';
        let selectedRaches = '';
        if (option || option !== null) {
            option.map((item) => {
                return (selectedRaches += item.value + ',');
            });
            ranch = selectedRaches.substring(0, selectedRaches.length - 1);
            setRanchFilter(ranch);
            setisRanchClear(option);
        } else {
            setRanchFilter('');
            setisRanchClear([]);
        }
    };

    const handleOnChangeOfFactor = (option) => {
        let factor = '';
        let selectedFactor = '';
        if (option || option !== null) {
            option.map((item) => {
                return (selectedFactor += item.value + ',');
            });
            factor = selectedFactor.substring(0, selectedFactor.length - 1);
            setFactorTypeFilter(factor);
        } else {
            setFactorTypeFilter('');
        }
    };
    const getData = (producingareacode, ranchnumber, factortype, berrytype, growernbr, callback) => {
        props.auth.getAccessToken().then((token) => {
            const loggedInUser = DuAuthenticationUtilities.GetOktaId(token);
            props.auth.getUser().then(() => {
                Middleware.Send(
                    PAGE_TYPE,
                    token,
                    APIEndPoints.GET_DISPOSITION_PREFERENCES(loggedInUser, producingareacode, ranchnumber, factortype, berrytype, growernbr),
                    'GET',
                    {},
                    { loadingMessage: 'Fetching Grower Preferences...' }
                )
                    .then((data) => {
                        if (data) {
                            props.setGrowerPreferencesData(data);
                            callback(data);
                        } else {
                            props.setGrowerPreferencesData([]);
                            callback([]);
                        }
                    })
                    .catch(() => {
                        let message = 'Something happened, but I am not sure if it is good or bad!';

                        props.setGrowerPreferencesData([]);

                        setMessageDialog({
                            showMessage: true,
                            message: message,
                            severity: 'error'
                        });
                    });
            });
        });
    };

    const getFactorTypeData = (callback) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.GET_M_LOOKUP('Factor Types'), 'GET', { Type: PAGE_TYPE }, { loadingMessage: '' })
                .then((data) => {
                    callback(data);
                })
                .catch(() => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';

                    props.setMLookUpData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    const gridDataFormatter = (data) => {
        const modifiedData = [];
        if (data) {
            let { ProducingAreaCode, FruitCondition, RejectionThreshold } = data[0];
            modifiedData.push({
                ProducingAreaCode: ProducingAreaCode,
                FruitCondition: FruitCondition,
                RejectionThreshold: RejectionThreshold,
                CreatedBy: props.loggedInUser,
                CreatedDateTime: new Date().toISOString()
            });
            return modifiedData;
        } else {
            return [];
        }
    };

    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }}
            className={props.classes.layout}
        >
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcMultiSelect
                        options={producingAreaByUser}
                        label={PRODUCING_AREA}
                        placeholder={PRODUCING_AREA_PLACEHOLDER}
                        isMulti={true}
                        onChange={handleOnChangeOfProducingArea}
                        name="producingarea"
                    ></DrcMultiSelect>
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcMultiSelect
                        value={isGrowerClear}
                        isClearable={true}
                        options={grower}
                        label={GROWER_NAME}
                        placeholder={GROWER_NAME_PLACEHOLDER}
                        isMulti={true}
                        onChange={handleOnChangeOfGrower}
                        name="Grower"
                    ></DrcMultiSelect>
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcMultiSelect
                        value={isRanchClear}
                        isClearable={true}
                        options={ranch}
                        label={RANCH_NAME}
                        placeholder={RANCH_NUMBER_PLACEHOLDER}
                        isMulti={true}
                        onChange={handleOnChangeOfRanch}
                        name="Ranch"
                    ></DrcMultiSelect>
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcMultiSelect
                        isClearable={true}
                        options={factorType}
                        label={FACTOR_TYPE}
                        placeholder={FACTOR_TYPE_PLACEHOLDER}
                        isMulti={true}
                        onChange={handleOnChangeOfFactor}
                        name="FactorType"
                    ></DrcMultiSelect>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcSelect
                        isClearable={true}
                        label={BERRY_TYPE}
                        options={props.berryType}
                        value={BerryType}
                        placeholder={BERRY_TYPE_PLACEHOLDER}
                        onChange={handleBerryTypeChange}
                    />
                </Grid>
            </Grid>
            <DrcPageRejectionThreshold
                classes={classes}
                className={classes.dialog}
                fullHeightOffset={10}
                fullWidth={true}
                data={formattedData}
                type={PAGE_TITLE}
                readOnly={false}
                columns={columns}
                hideCount={true}
                settings={{
                    EnableExport: true,
                    ActionColumnSetting: {},
                    EnableAdd: false,
                    EnableEdit: false,
                    EnableDelete: false
                }}
                gridDataFormatter={gridDataFormatter}
            />

            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </DrcPanel>
    );
};

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        producingArea: state.overrideReducer.producingArea,
        userGroup: state.masterReducer.userGroup,
        loggedInUser: state.masterReducer.loggedInUser,
        berryType: state.pointMatrixReducer.berryType,
        userProducingArea: state.RejectionThresholdReducer.userProducingArea,
        mLookupList: state.RejectionThresholdReducer.mLookupList,
        RachList: state.growerPreferencesReducer.RachList,
        FactorTypeList: state.growerPreferencesReducer.FactorTypeList,
        GrowerPreferencesList: state.growerPreferencesReducer.GrowerPreferencesList
    };
}
export default connect(mapStateToProps, { setGrowerData, setFactorTypeData, setGrowerPreferencesData, setRanchData })(
    withAuth(withStyles(styles)(GrowerPreferences))
);
