import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DuDateUtilities } from 'driscolls-react-utilities';

let styles = (theme) => ({
    root: {
        marginTop: '7px',
        marginBottom: '5px',
        '& .MuiButtonBase-root.MuiIconButton-root': {
            marginRight: '-15px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.buttonBorder + theme.important
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.accent.primary + theme.important
        },
        '& .MuiInputAdornment-root button': {
            color: theme.light.text.primary + theme.important
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.text.primary + theme.important
        },
        [theme.darkTheme]: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.buttonBorder + theme.important
            },
            '& .MuiFormLabel-root': {
                color: theme.dark.text.secondary + theme.important
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.dark.text.primary + theme.important
                }
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.accent.primary + theme.important
            },
            '& input.MuiInputBase-input': {
                color: theme.dark.text.primary + theme.important
            },
            '& .MuiInputAdornment-root button': {
                color: theme.dark.text.primary + theme.important
            }
        }
    },
    dialog: {
        '& .MuiToolbar-root': {
            display: 'block',
            height: '45px',
            minHeight: '45px'
        },
        '& .MuiPickersToolbarButton-toolbarBtn:nth-of-type(1)': {
            marginLeft: theme.spacing(2)
        },
        '& .MuiPickersToolbarButton-toolbarBtn:nth-of-type(2)': {
            float: 'left'
        },
        '& .MuiPickersToolbarText-toolbarTxt': {
            fontSize: '2rem',
            lineHeight: '1.75'
        },
        '& .MuiTypography-body2, & .MuiTypography-caption': {
            fontSize: '1rem'
        }
    },
    labelError: {
        color: theme.light.text.errorTitle,
        fontSize: '1.25rem',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

const todaysDate = new Date();
const todaysFormattedDate = DuDateUtilities.ToString(todaysDate);

class DrcDatePicker extends React.Component {
    render() {
        const {
            label,
            placeholder,
            format,
            variant,
            helperText,
            width,
            style,
            InputProps,
            InputLabelProps,
            showTodayButton,
            classes,
            selectedDate,
            onChange,
            onAccept,
            onClose,
            onOpen,
            required,
            ...other
        } = this.props;

        return (
            <KeyboardDatePicker
                className={classes.root}
                style={{ width: width || '100%', ...style }}
                fullWidth={true}
                margin="dense"
                inputVariant="outlined"
                variant={variant}
                label={
                    required ? (
                        <span>
                            {label || ''} <span className={classes.labelError}>*</span>
                        </span>
                    ) : (
                        label || ''
                    )
                }
                placeholder={placeholder || todaysFormattedDate}
                value={selectedDate}
                onChange={onChange}
                onAccept={onAccept}
                onClose={onClose}
                onOpen={onOpen}
                format={format || 'MMM-dd-yyyy'}
                clearable={true}
                showTodayButton={showTodayButton}
                InputProps={{
                    ...InputProps
                }}
                InputLabelProps={{
                    ...InputLabelProps
                }}
                DialogProps={{ className: classes.dialog }}
                helperText={helperText || ''}
                error={(helperText || '').length > 0}
                refuse={/[^A-Za-z0-9]+/gi}
                {...other}
            />
        );
    }
}

export default withStyles(styles)(DrcDatePicker);
