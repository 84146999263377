import { SUMMARY_LIST, USER_PRODUCING_AREA } from '../Constant/ActionConstant';

export const setSummaryStatsData = (data) => ({
    type: SUMMARY_LIST,
    payload: data
});
export const setUserProducingArea = (data) => ({
    type: USER_PRODUCING_AREA,
    payload: data
});
