import { THRESHOLD_LIST_DATA,USER_PRODUCING_AREA_THRESHOLD,M_LOOKUP_LIST_DATA} from '../Constant/ActionConstant';

export const setThresholdData = (data) => ({
    type: THRESHOLD_LIST_DATA,
    payload: data
});

export const setUserProducingAreaThreshold = (data) => ({
    type: USER_PRODUCING_AREA_THRESHOLD,
    payload: data
});
export const setMLookUpData = (data) => ({
    type: M_LOOKUP_LIST_DATA,
    payload: data
});

