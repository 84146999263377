import React from 'react';
import TabRouting from '../../components/TabRouting';

const selectedMainTabIndex = 0;
const selectedSubTabIndex = 0;
const LocationMain = () => {
    return <TabRouting selectedMainTabIndex={selectedMainTabIndex} selectedSubTabIndex={selectedSubTabIndex}></TabRouting>;
};

export default LocationMain;
