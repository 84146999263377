import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import { DrcMain } from 'driscolls-react-components';
import DrcTab from './DrcTab/DrcTab';
import { setPageTitleAction, setPageDirty } from '../actions/actions';
import PointMatrixCreateTab from '../pages/PointMatrix/PointMatrix';
import { handleRouting, routingOnTabChange } from '../util/navigation';
import DirtyCheck from './DirtyCheck';
import { AnalystTabs } from '../Constant/Tabs';
import MasterDataUtilities from '../data/MasterDataUtilities';

let changingTabIndex = 0;
const AnalystTabRouting = (props) => {
    const [screenHasDataChanges, setScreenHasDataChanges] = useState(false);

    useEffect(() => {
        let pageTitle = handleRouting(props.match.path);
        props.setPageTitle(pageTitle);
    }, [props.match.path]);

    const handleConfirmReject = () => {
        setScreenHasDataChanges(false);
        setPageDirty(false);
        continueNavigation();
    };

    const handleMainTabChange = (index) => {
        changingTabIndex = index;
        if (props.isPageDirty) {
            setScreenHasDataChanges(true);
            return;
        }
        continueNavigation();
    };
    const continueNavigation = () => {
        let title = routingOnTabChange(changingTabIndex, props.history);
        setPageTitle(title);
    };

    const handleCancelNavigation = () => {
        setScreenHasDataChanges(false);
    };

    const renderSwitch = () => {
        switch (selectedMainTabIndex) {
            case 0:
                return (
                    <PointMatrixCreateTab
                        subTabItems={AnalystTabs.pointMatrixTabs.Matrix}
                        selectedSubTabIndex={selectedSubTabIndex}
                    ></PointMatrixCreateTab>
                );
            default:
                return;
        }
    };

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    const { setPageTitle, setPageDirty, selectedMainTabIndex, selectedSubTabIndex } = props;
    return (
        <DrcMain transparent>
            <DrcTab
                selectedMenuIndex={selectedMainTabIndex}
                menuItems={AnalystTabs.mainTabs}
                handleMenuItemChange={handleMainTabChange}
                centered
            ></DrcTab>
            {renderSwitch()}
            {screenHasDataChanges && (
                <DirtyCheck
                    screenHasDataChanges={screenHasDataChanges}
                    handleConfirmReject={handleConfirmReject}
                    handleCancelNavigation={handleCancelNavigation}
                ></DirtyCheck>
            )}
        </DrcMain>
    );
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isPageDirty: state.rootReducer.isPageDirty
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setPageDirty: (isDirty) => dispatch(setPageDirty(isDirty))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(AnalystTabRouting)));
