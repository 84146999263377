import React, { useState, useEffect, useCallback } from 'react';
import { DrcButton, DrcDialog, DrcPageWarning, DrcPanel, DrcKeyValueTable } from 'driscolls-react-components';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { startDate, endDate } from '../../util/validation';
import { saveFetchedRanchData, updateSaveEnable } from '../../actions/MasterActions';
import DrcDatePicker from './DrcDatePicker';

const HORIZONTAL = 'horizontal';

let styles = () => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '600px',
            width: '100%'
        }
    }
});
const EditDialog = (props) => {
    const [dialogContent, setDialogContent] = useState({ ...props.content });
    const [editArray, setEditArray] = useState([]);
    const [readOnlyArray, setReadOnlyArray] = useState([]);
    useEffect(() => {
        console.log('Check useEffect');
        setDialogContent(props.content);
        buildEditArray();
    }, []);

    const { columns, updateSaveEnable } = props;

    // Validate input fields, If there is and change in input field w.r.t to field old value then flag would be
    // set to enable the submit button
    const allFieldsFilled = useCallback(() => {
        let saveAllowed = true,
            editSaveAllowed = false;
        columns.forEach((value) => {
            if (dialogContent.editedValue[value.key] === '' || dialogContent.editedValue[value.key] === undefined) {
                saveAllowed = false;
            }
            if (dialogContent.isEdit) {
                if (dialogContent.editedValue[value.key] === dialogContent.oldValue[value.key]) {
                    saveAllowed = false;
                } else {
                    editSaveAllowed = true;
                }
            }
        });
        return editSaveAllowed || saveAllowed;
    }, [columns, dialogContent.editedValue, dialogContent.isEdit, dialogContent.oldValue]);

    //Hide error message
    const hideErrorMessage = useCallback(() => {
        setDialogContent({ ...dialogContent, isError: false, errorMessage: '' });
    }, [dialogContent]);

    const handleStartDate = useCallback(
        (event, i) => {
            dialogContent.editedValue.isEdit = true;
            let oStartDate = startDate(event, dialogContent.editedValue);
            let helperText = dialogContent.helperText;
            helperText[i] = oStartDate.helperText;
            let newEdit = dialogContent.editedValue;
            newEdit.StartDate = oStartDate.StartDate;
            newEdit.startDateString = oStartDate.startDateString;
            if (oStartDate.showErrorMessage) {
                setDialogContent({
                    ...dialogContent,
                    editedValue: newEdit,
                    isError: true,
                    errorMessage: 'Expected Start Date cannot be greater than End Date.'
                });

                setTimeout(() => {
                    hideErrorMessage();
                }, 3000);
            } else {
                let submitEnabled;
                let newErrorCount;
                //To disable the save button when date format or value is not valid
                if (oStartDate.StartDate === 'N/A') {
                    submitEnabled = false;
                } else {
                    newErrorCount = helperText.filter((ht) => ht !== '').length;
                    submitEnabled = allFieldsFilled() && newErrorCount <= 0;
                }
                setDialogContent(
                    {
                        ...dialogContent,
                        editedValue: newEdit,
                        isError: false,
                        errorMessage: ''
                    },
                    buildEditArray(dialogContent)
                );
                updateSaveEnable(submitEnabled);
            }
        },
        [dialogContent, allFieldsFilled, hideErrorMessage, updateSaveEnable]
    );

    //Validate end date - It should be greate or equal to the start date
    const handleEndDate = useCallback(
        (event, i) => {
            dialogContent.editedValue.isEdit = true;
            let oEndDate = endDate(event, dialogContent.editedValue);
            let helperText = dialogContent.helperText;
            helperText[i] = oEndDate.helperText;
            let newEdit = dialogContent.editedValue;
            newEdit.EndDate = oEndDate.EndDate;
            newEdit.endDateString = oEndDate.endDateString;
            if (oEndDate.showErrorMessage) {
                setDialogContent({
                    ...dialogContent,
                    editedValue: newEdit,
                    isError: true,
                    errorMessage: 'End Date cannot be less than Start Date.'
                });

                setTimeout(() => {
                    hideErrorMessage();
                }, 3000);
            } else {
                let submitEnabled;
                let newErrorCount;
                //To disable the save button when date format or value is not valid
                if (oEndDate.EndDate === 'N/A') {
                    submitEnabled = false;
                } else {
                    newErrorCount = helperText.filter((ht) => ht !== '').length;
                    submitEnabled = allFieldsFilled() && newErrorCount <= 0;
                }

                setDialogContent(
                    {
                        ...dialogContent,
                        editedValue: newEdit,
                        isError: false,
                        errorMessage: ''
                    },
                    buildEditArray(dialogContent)
                );
                updateSaveEnable(submitEnabled);
            }
        },
        [dialogContent, allFieldsFilled, hideErrorMessage, updateSaveEnable]
    );

    // Creating UI input fields based on the column inputType provided
    const buildEditArray = useCallback(() => {
        const returnArray = [];
        let returnReadOnlyArray = [];
        let orient = props.orientation || HORIZONTAL;
        columns.forEach(function (c, i) {
            if (c.key !== 'actions') {
                if (c.isDisabled === true) {
                    returnReadOnlyArray.push(
                        <div className="col-sm-6" key={i}>
                            <DrcKeyValueTable>{[{ key: c.name, value: dialogContent.editedValue[c.key] }]}</DrcKeyValueTable>
                        </div>
                    );
                } else if (c.inputType === 'dateTime') {
                    returnArray.push(
                        <div className={orient === HORIZONTAL ? 'col-xs-12 col-sm-6' : 'col-xs-12'}>
                            <DrcDatePicker
                                label={c.name}
                                placeholder={c.name}
                                InputLabelProps={{ shrink: true }}
                                value={c.key === 'startDateString' ? dialogContent.editedValue.StartDate : dialogContent.editedValue.EndDate}
                                helperText={dialogContent.helperText[i]}
                                onChange={c.key === 'startDateString' ? (evt) => handleStartDate(evt, i) : (evt) => handleEndDate(evt, i)}
                                autoOk
                                disableToolbar={true}
                                variant="inline"
                                minDate={new Date()}
                                clearable
                            />
                        </div>
                    );
                }
            }
        });
        if (returnArray.length !== 0) {
            setEditArray(returnArray);
            setReadOnlyArray(returnReadOnlyArray);
        }
    }, [columns, dialogContent.editedValue, dialogContent.oldValue.StartDate, handleEndDate, handleStartDate, props.orientation]);
    // Validate start date- it should be less or equal to the end date

    const onAddEditNo = () => {
        props.updateSaveEnable(false);
        props.onSave('close');
    };

    const onAddEditYes = () => {
        let loggedInUser = '';
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            loggedInUser = user.email.split('@')[0];
            dialogContent.editedValue.ModifiedBy = loggedInUser;
        }

        props.onSave('edit', null, null, dialogContent.oldValue, dialogContent.editedValue, dialogContent.helperText);
        props.updateSaveEnable(false);
    };

    return (
        <React.Fragment>
            {dialogContent.isError ? <DrcPageWarning anchorVertical="top" message={dialogContent.errorMessage} /> : null}
            {dialogContent.dialogEditOpen && (
                <DrcDialog
                    className={props.classes.dialog}
                    maxWidth={'100%'}
                    open={dialogContent.dialogEditOpen}
                    buttons={
                        <React.Fragment>
                            <DrcButton isSecondary onClick={onAddEditNo} floatRight disabled={dialogContent.isDisabledCancel}>
                                Cancel
                            </DrcButton>
                            <DrcButton isPrimary disabled={!props.updateSaveAction} onClick={onAddEditYes} floatRight>
                                Save
                            </DrcButton>
                        </React.Fragment>
                    }
                    title="Edit Type: Ranch Override"
                >
                    <hr />
                    {readOnlyArray.length === 0 ? null : (
                        <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                            <div className="row">{readOnlyArray}</div>
                        </DrcPanel>
                    )}
                    <div className="row" style={{ alignItems: 'center' }}>
                        {editArray}
                    </div>
                </DrcDialog>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        aFetchedRanchData: state.masterReducer.fetchedRanchData,
        updateSaveAction: state.masterReducer.updateSaveAction
    };
};

export default connect(mapStateToProps, { saveFetchedRanchData, updateSaveEnable })(withRouter(withAuth(withStyles(styles)(EditDialog))));
