import Parameters from './parameters';
class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;
    static MASTER_DATA = '/MasterData/';
    static APPLICATION = '/Application/';

    static MASTER_DATA_APPLICATIONS = this.BASE + this.MASTER_DATA + 'Applications';
    static MASTER_DATA_APPLICATION_TYPES = this.BASE + this.MASTER_DATA + 'ApplicationTypes';
    static MASTER_DATA_APPLICATION_ASSOCIATIONS = this.BASE + this.MASTER_DATA + 'ApplicationAssociations';
    static MASTER_DATA_WORK_STATUSES = this.BASE + this.MASTER_DATA + 'WorkStatuses';
    static MASTER_DATA_WORK_TYPES = this.BASE + this.MASTER_DATA + 'WorkTypes';
    static MASTER_DATA_STATS = this.BASE + this.MASTER_DATA + 'Stats';
    static ALL_MASTER_DATA = this.BASE + this.MASTER_DATA + 'All';
    static GET_WAREHOUSES = this.BASE + 'master/warehouse';
    static OVERRIDE_DATA = this.BASE + 'ranch/override';
    static QUARTILE_LOCATION_DATA = '/staticdata/QuartileLocation.json';
    static POST_INSPECTION_BYPASS = this.BASE + 'inspectionbypass/berrytype';
    static POST_NOTIFICATION_DATA = this.BASE + 'notification';
    static GET_PRODUCING_AREA = this.BASE + 'master/producingarea';
    static GET_LABEL = this.BASE + 'master/label';
    static GET_BERRY_TYPE = this.BASE + 'master/berrytype';
    static POST_POINT_MATRIX = this.BASE + 'pointmatrix';
    static POST_PRODUCING_AREA = this.BASE + 'qamanager';
    static QUARTILE_BYPASS_DATA = '/staticdata/QuartileByPass.json';
    static GET_LOCAL_MANAGER_WAREHOUSES = this.BASE + 'managerwarehouses';
    static DISTRICT_GROUPS = this.BASE + 'listdistrictgroup';
    static SIMULATE = this.BASE + 'pointmatrix/brixsimulate';
    static SAVE_DISPOSITION_OPTION = this.BASE + 'disposition/options';

    static GET_LOCAL_MANAGER_WAREHOUSES = this.BASE + 'managerwarehouses';

    static QUARTILE_ESTIMATED_GROUP = (berrytype) => {
        return `${this.BASE}listestimategroup?${Parameters.BERRY_TYPE}=${berrytype}`;
    };

    static GET_ASSIGNED_PRODUCING_AREA = (producingArea) => {
        return `${this.BASE}/${Parameters.PRODUCING_AREA}=${producingArea}`;
    };

    static RANCH_DETAILS = (producingArea) => {
        return `${this.BASE}master/listranch?${Parameters.PRODUCING_AREA}=${producingArea}`;
    };
    //TODO: This is not needed, but is a good example for what we will need in the future.
    static FP_BY_CODE = (itemId) => {
        return `${this.FP_LINE_DETAILS}/${itemId}`;
    };

    static GET_WARE_HOUSE_BY_BERRY_TYPE = (berryType) => {
        return `${this.BASE}inspectionbypass/berrytype?${Parameters.BERRY_TYPE}=${berryType}`;
    };

    static POST_WARE_HOUSE_LOCATION_BYPASS = `${this.BASE}inspectionbypass/berrytype`;

    static GET_BERRY_TYPE_BY_WAREHOUSE = (warehouse, berryType) => {
        return `${this.BASE}inspectionbypass/quartile?${Parameters.WAREHOUSENUMBER}=${warehouse}&${Parameters.BERRY_TYPE}=${berryType}`;
    };

    static NOTIFICATION_DATA = (warehouse) => {
        return `${this.BASE}notification?${Parameters.WAREHOUSENUMBER}=${warehouse}`;
    };

    static GET_VARIETY = (berryType, producingArea) => {
        return `${this.BASE}master/variety?${Parameters.BERRY_TYPE}=${berryType}&${Parameters.PRODUCING_AREA}=${producingArea
            .split(',')
            .join(`&${Parameters.PRODUCING_AREA}`)}`;
    };

    static GET_QUARTILE_VARIETY = (berryType) => {
        return `${this.BASE}master/variety?${Parameters.BERRY_TYPE}=${berryType}`;
    };

    static GET_LOOKUP_DATA = (pointmatrixid) => {
        return `${this.BASE}pointmatrix?${Parameters.POINT_MATRIX_ID}=${pointmatrixid}`;
    };

    static GET_POINT_MATRIX_DATA = (
        districtGroup,
        estimateGroup,
        attributeType,
        label,
        poolweekstartdate,
        poolweekenddate,
        varietycode,
        berrytype,
        varietydescription,
        status
    ) => {
        if (varietycode) {
            return `${this.BASE}pointmatrix?${Parameters.QMP_DISTRICT_GROUP}=${districtGroup}&${Parameters.ESTIMATE_GROUP}=${estimateGroup}&${Parameters.ATTRIBUTE_TYPE}=${attributeType}&${Parameters.LABEL}=${label}&${Parameters.POOL_WEEK_START_DATE}=${poolweekstartdate}&${Parameters.POOL_WEEK_END_DATE}=${poolweekenddate}&${Parameters.BERRY_TYPE}=${berrytype}&${Parameters.VARIETY_CODE}=${varietycode}&${Parameters.VARIETY_DESC}=${varietydescription}&${Parameters.STATUS_POINT_MATRIX}=${status}`;
        } else {
            return `${this.BASE}pointmatrix?${Parameters.QMP_DISTRICT_GROUP}=${districtGroup}&${Parameters.ESTIMATE_GROUP}=${estimateGroup}&${Parameters.ATTRIBUTE_TYPE}=${attributeType}&${Parameters.LABEL}=${label}&${Parameters.POOL_WEEK_START_DATE}=${poolweekstartdate}&${Parameters.POOL_WEEK_END_DATE}=${poolweekenddate}&${Parameters.BERRY_TYPE}=${berrytype}&${Parameters.STATUS_POINT_MATRIX}=${status}`;
        }
    };

    static GET_ASSIGNED_PRODUCING_AREAS = (producingAreaCode) => {
        if (producingAreaCode) {
            return `${this.BASE}qamanager?${Parameters.PRODUCING_AREA_CODE}=${producingAreaCode}`;
        } else {
            return `${this.BASE}qamanager`;
        }
    };

    static GET_USER_PRODUCING_AREAS = (email) => {
        return `${this.BASE}qamanager?${Parameters.EMAIL_ADDRESS}=${email}`;
    };
    static GET_DISPOSITION = (producingAreaCode, berryType) => {
        return `${this.BASE}disposition/options?${Parameters.PRODUCING_AREA_CODE}=${producingAreaCode}&${Parameters.BERRY_TYPE}=${berryType}`;
    };

    static GET_RJECTION_THRESHOLD = (email, producingAreaCode, berryType) => {
        return `${this.BASE}rejectionthreshold?isactive=1&email=${email}&${producingAreaCode ? `producingareacode=${producingAreaCode}` : null}&${
            berryType ? `berrytype=${berryType}` : null
        }`;
    };

    static POST_RJECTION_THRESHOLD = `${this.BASE}rejectionthreshold`;

    static GET_M_LOOKUP = (FactorTypes) => {
        return `${this.BASE}lookupvalues?${Parameters.QMP_LOOKUP_TYPE}=${FactorTypes}`;
    };
    static POST_M_LOOKUP = `${this.BASE}lookupvalues`;

    static GET_DISPOSITION_PREFERENCES = (userId, producingareacode, ranchnumber, factortype, berrytype, growernbr) => {
        return `${this.BASE}disposition/preferences?${Parameters.QMP_USER_ID}=${userId}&${
            producingareacode && `producingareacode=${producingareacode}`
        }&${ranchnumber && `ranchnumber=${ranchnumber}`}&${growernbr && `companynumber=${growernbr}`}&${berrytype && `berrytype=${berrytype}`}&${
            factortype && `factortype=${factortype}`
        }`;
    };

    static GET_GROWER_BY_PRODUCING_AREA = (producingareacode) => {
        return `${this.BASE}master/producingarea/grower?${Parameters.PRODUCING_AREA_CODE}=${producingareacode}`;
    };

    static GET_RACHES_BY_GROWER = (growerNbr) => {
        return `${this.BASE}master/growers/ranches?${Parameters.GROWER_NUMBER}=${growerNbr}`;
    };
    static GET_M_LOOKUP_REGION = (Region) => {
        return `${this.BASE}lookupvalues?${Parameters.QMP_LOOKUP_TYPE}=${Region}`;
    };
    static GET_PRODUCING_AREA_REGION = (producingareacode, region) => {
        return `${this.BASE}producingarearegion?${producingareacode ? `&producingareacode=${producingareacode.split(' ')[0]}` : ''}${
            region ? `&region=${region}` : ''
        }`;
    };
    static GET_SUMMARY_STATISTICS = (producingareacode, commoditycode, shortname, oldshortname) => {

        //form query string
        let queryString=new URLSearchParams();
        if(producingareacode)queryString.append('producingareacode',producingareacode)
        if(commoditycode)queryString.append('commoditycode',commoditycode)
        if(shortname)queryString.append('shortname',shortname)
        if(oldshortname)queryString.append('oldshortname',oldshortname)

        let url=`${this.BASE}summarystatistics${queryString.toString() ? `?${queryString}` : ''}`;

        return url;

    };
    static POST_PRODUCING_AREA_REGION = `${this.BASE}producingarearegion`;
    static POST_SUMMARY_STATISTICS = `${this.BASE}summarystatistics`;
}

export default APIEndPoints;
